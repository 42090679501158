<template>
    <div
        :class="{
            programCard: true,
            dummy: dummy
        }"
    >
        <div
            :class="`header ${
                (program.recording && program.expire) || program.availableAt
                    ? 'thumbnailContent'
                    : ''
            }`"
        >
            <img
                v-if="program.thumbnail"
                class="thumbnail"
                v-bind:src="program.thumbnail"
                alt=""
                onerror="this.onerror=null; this.src='/img/placeholder_img.png'"
            />
            <div
                class="channelLogos"
                v-if="program.channelLogo || program.epgList?.[0]?.channelLogo"
            >
                <img
                    class="channelLogo"
                    :src="
                        program.channelLogo || program.epgList?.[0]?.channelLogo
                    "
                />
            </div>
            <div
                class="wrapper"
                style="position: absolute; right: 3px; bottom: 3px; left: 3px"
            >
                <span class="expireDate" v-if="program.availableAt">{{
                    program.availableAt
                }}</span>
                <span
                    class="expireDate"
                    v-if="program.recording && program.expire"
                    >{{ generateExpireLabel(program.expire) }}</span
                >
                <ProgressBar
                    v-if="mode != 'streaming'"
                    :start="new Date(program.start)"
                    :end="new Date(program.end)"
                    :progress="
                        program.position && program.position > 10
                            ? program.position
                            : null
                    "
                    :duration="program.duration"
                />
            </div>
        </div>
        <div class="programEpg">
            <p class="programStartTime">{{ timestamp }}</p>
            <div v-if="mode != 'streaming'">
                <p
                    class="duration"
                    v-if="program.startTime && program.endTime && program.live"
                >
                    {{ correctClockNum(program.startTime.getHours()) }}:{{
                        correctClockNum(program.startTime.getMinutes())
                    }}
                    - {{ correctClockNum(program.endTime.getHours()) }}:{{
                        correctClockNum(program.endTime.getMinutes())
                    }}
                </p>
                <p
                    class="duration"
                    v-else-if="program.startTime && !program.live"
                >
                    {{ correctClockNum(program.startTime.getDate()) }}/{{
                        correctClockNum(program.startTime.getMonth() + 1)
                    }}
                    - kl. {{ correctClockNum(program.startTime.getHours()) }}:{{
                        correctClockNum(program.startTime.getMinutes())
                    }}
                </p>
            </div>
            <!-- <p class="duration" v-else-if="duration">{{ duration }} min.</p> -->
            <h3 class="programName">
                {{ program.title
                }}<span v-if="program.series" class="programSeries">{{
                    constructSeasonString(program.series)
                }}</span>
            </h3>

            <p class="programSubtitle">{{ program.subTitle }}</p>
            <p class="nextProgram" v-if="program.nextProgram">
                Næste: {{ program.nextProgram.title }}
            </p>
            <p class="profile" v-if="program.profile && !profileEditable">
                <svg
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="user"
                        d="M27,24.23669V27a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V24.23669a1.57806,1.57806,0,0,1,.93115-1.36462L10.0672,20.167A5.02379,5.02379,0,0,0,14.55273,23h1.89454a5.02336,5.02336,0,0,0,4.48535-2.83313l5.13623,2.7052A1.57806,1.57806,0,0,1,27,24.23669ZM9.64478,14.12573a2.99143,2.99143,0,0,0,1.31073,1.462l.66583,3.05176A2.99994,2.99994,0,0,0,14.55237,21h1.89526a2.99994,2.99994,0,0,0,2.931-2.36047l.66583-3.05176a2.99115,2.99115,0,0,0,1.31073-1.462l.28-.75146A1.2749,1.2749,0,0,0,21,11.62988V9c0-3-2-5-5.5-5S10,6,10,9v2.62988a1.2749,1.2749,0,0,0-.63519,1.74439Z"
                    />
                </svg>
                <span>{{ program.profile.name }}</span>
            </p>
            <div class="profilePicker" v-if="profileEditable">
                <svg
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="user"
                        d="M27,24.23669V27a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V24.23669a1.57806,1.57806,0,0,1,.93115-1.36462L10.0672,20.167A5.02379,5.02379,0,0,0,14.55273,23h1.89454a5.02336,5.02336,0,0,0,4.48535-2.83313l5.13623,2.7052A1.57806,1.57806,0,0,1,27,24.23669ZM9.64478,14.12573a2.99143,2.99143,0,0,0,1.31073,1.462l.66583,3.05176A2.99994,2.99994,0,0,0,14.55237,21h1.89526a2.99994,2.99994,0,0,0,2.931-2.36047l.66583-3.05176a2.99115,2.99115,0,0,0,1.31073-1.462l.28-.75146A1.2749,1.2749,0,0,0,21,11.62988V9c0-3-2-5-5.5-5S10,6,10,9v2.62988a1.2749,1.2749,0,0,0-.63519,1.74439Z"
                    />
                </svg>
                <select
                    @change="
                        (e) => {
                            setProgramProfile(selectedProfile, program);
                        }
                    "
                    @click.stop="() => {}"
                    name="profileSelect"
                    id="profileSelect"
                    v-model="selectedProfile"
                >
                    <option value="" v-if="!program.profile">Ingen</option>
                    <option
                        v-for="profile in profiles"
                        :key="profile._id"
                        :value="profile._id"
                    >
                        {{ profile.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from './ProgressBar.vue';
import axios from 'axios';
import message from '@/components/statusMessage';
export default {
    name: 'ProgramCard',
    props: {
        profileEditable: Boolean,
        program: Object,
        dummy: Boolean,
        mode: String,
        profiles: Array
    },
    data() {
        return {
            selectedProfile: this.program.profile
                ? this.program.profile._id
                : ''
        };
    },
    computed: {
        timestamp() {
            let program = this.program;
            if (!program.start) {
                return '';
            }
            let stamp = new Date(program.start);
            let date = this.correctClockNum(stamp.getDate());
            let month = new Date(stamp).toLocaleString(
                window.navigator.language,
                { month: 'short' }
            );
            let year = stamp.getFullYear();
            let hour = this.correctClockNum(stamp.getHours());
            let minutes = this.correctClockNum(stamp.getMinutes());
            if (this.mode == 'tv' && program.end) {
                let endStamp = new Date(program.end);
                let endHour = this.correctClockNum(endStamp.getHours());
                let endMinutes = this.correctClockNum(endStamp.getMinutes());
                return `${hour}.${minutes}-${endHour}.${endMinutes}`;
            } else {
                return `${date}. ${month}. ${year} ${hour}.${minutes}`;
            }
        }
    },
    methods: {
        correctClockNum(number) {
            return number < 10 ? '0' + number : number;
        },
        constructSeasonString(seriesObj) {
            let season = seriesObj.season ? `S${seriesObj.season}` : '';
            let episode = seriesObj.episode ? `E${seriesObj.episode}` : '';
            return `${season} ${episode}`;
        },
        setProgramProfile(profileId, program) {
            let token = window.localStorage.getItem('token');
            if (!token) return;
            if (!profileId) {
                message.Error(
                    'Det er ikke muligt at fjerne optagelse fra profil'
                );
                return;
            }
            let profile = program.profile
                ? `/profile/${program.profile._id}`
                : '/profile';
            let dataUrl = `${this.$store.state.apiUrl}recording/${program._id}${profile}?token=${token}`;
            axios
                .post(dataUrl, {
                    profile: profileId
                })
                .then(() => {
                    message.Success(`Flyttede ${program.title}`);
                })
                .catch((err) => {
                    console.error(err);
                    message.Error('Kunne ikke flytte optagelsen');
                    this.selectedProfile = program.profile
                        ? program.profile._id
                        : '';
                });
        },
        generateExpireLabel(_date) {
            let date = new Date(_date);
            return `Udløber: ${this.correctClockNum(
                date.getDate()
            )}/${this.correctClockNum(
                date.getMonth() + 1
            )}-${date.getFullYear()}`;
        }
    },
    components: {
        ProgressBar
    }
};
</script>

<style lang="scss">
@import './variables.scss';
.programCard {
    position: relative;
    border-radius: 10px;
    background-color: rgb(var(--secondaryBackground));
    text-align: left;
    color: whitesmoke;
    overflow: hidden;
    // width: clamp(200px, 16vw, 300px);
    flex-grow: 1;
    min-width: 250px;
    max-width: 350px;
    cursor: pointer;
    transform: scale(1);
    display: flex;
    flex-direction: column;
    &:hover {
        box-shadow: 0 0 3px 4px rgba(16, 16, 16, 0.638);
        transform: scale(1.05);
    }
    .header {
        box-shadow: 0 0 3px 4px black inset;
        height: 150px;
        width: 100%;
        position: relative;
        background-color: rgb(var(--tertiaryBackground));
        .wrapper {
            position: relative;
            z-index: 2;
            .expireDate {
                font-size: 14px;
                color: rgb(var(--secondaryTextColor));
            }
        }
        .thumbnail {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
            background-position: 50% 50%;
        }
        .channelLogos {
            position: absolute;
            left: 0px;
            top: 0px;
            padding: 2px 5px;
            border-bottom-right-radius: 10px;
            background-color: rgba(211, 211, 211, 0.3);
            backdrop-filter: blur(6px);
            z-index: 1;
            height: auto;
            width: max-content;
            display: flex;
            max-width: 100%;
            overflow: hidden;
            .channelLogo {
                height: 50px;
                width: auto;
            }
        }
        &.thumbnailContent:after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 50px;
            background: linear-gradient(
                to top,
                rgb(var(--secondaryBackground)),
                rgba(var(--secondaryBackground), 0.6),
                transparent
            );
            z-index: 1;
            left: 0;
            right: 0;
        }
    }
    .programEpg {
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        .programName {
            font: $primaryHeading-small;
            color: rgb(var(--primaryTextColor));
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;

            span {
                font-size: 12px;
                color: rgb(var(--secondaryTextColor));
                margin-left: 5px;
            }
        }
        .duration {
            color: rgb(var(--secondaryTextColor));
        }
        .nextProgram {
            color: rgb(var(--secondaryTextColor));
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .profile {
            font-size: 14px;
            display: flex;
            color: rgb(var(--secondaryTextColor));
            align-items: center;
            svg {
                height: 20px;
                width: 20px;
                path {
                    fill: rgb(var(--secondaryTextColor));
                }
            }
        }
        .profilePicker {
            background-color: rgba(var(--primaryBackground), 0.3);
            padding: 5px 10px;
            border-radius: 5px;
            margin-top: auto;
            display: flex;
            align-items: center;
            select {
                background-color: transparent;
                color: rgb(var(--secondaryTextColor));
            }
            svg {
                height: 20px;
                width: 20px;
                path {
                    fill: rgb(var(--secondaryTextColor));
                }
            }
        }
    }
    &.dummy {
        transition: 0 !important;
        &::after {
            content: '';
            position: absolute;
            transform: skewX(-20deg);
            left: 0;
            background-color: rgba(var(--primaryBackground), 0.6);
            top: 0;
            bottom: 0;
            width: 40px;
            z-index: 2;
            box-shadow: 0 0 20px 20px rgba(var(--primaryBackground), 0.6);
            animation: dummyAnimation 2s forwards ease infinite;
        }
    }
}
</style>
