<template>
    <div
        class="guideProgramWrapper"
        :style="{
            width:
                startTime < startDate
                    ? calculateCustomWidth()
                    : program.duration / 1.5 + 'rem',
            marginLeft:
                first && startTime > startDate ? calculateStartMargin() : null
        }"
    >
        <div
            :class="`guideProgram ${
                new Date(program.availableUntil) < new Date() ? 'disabled' : ''
            }`"
            :style="{ marginLeft: first ? '0px' : null }"
        >
            <p class="duration">
                {{ correctTime(startTime.getHours()) }}:{{
                    correctTime(startTime.getMinutes())
                }}
            </p>
            <br />
            <h2 class="programTitle">
                {{ program.title }}
            </h2>
            <br />
            <p class="genres">
                <span
                    class="genre"
                    v-bind:key="genre"
                    v-for="(genre, index) in program.genres"
                >
                    {{
                        index + 1 >= program.genres.length
                            ? genre
                            : genre + ', '
                    }}
                </span>
            </p>
            <div class="markers">
                <svg
                    id="glyphicons-basic"
                    class="marker watchlist"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    v-if="program.watchList"
                >
                    <path
                        id="star"
                        d="M27.34766,14.17944l-6.39209,4.64307,2.43744,7.506a.65414.65414,0,0,1-.62238.85632.643.643,0,0,1-.38086-.12744l-6.38568-4.6383-6.38574,4.6383a.643.643,0,0,1-.38086.12744.65419.65419,0,0,1-.62238-.85632l2.43744-7.506L4.66046,14.17944A.65194.65194,0,0,1,5.04358,13h7.89978L15.384,5.48438a.652.652,0,0,1,1.24018,0L19.06476,13h7.89978A.652.652,0,0,1,27.34766,14.17944Z"
                    />
                </svg>
                <svg
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    class="marker recording"
                    v-if="program.recording"
                >
                    <path
                        id="circle-selected"
                        d="M16,5A11,11,0,1,0,27,16,11.01245,11.01245,0,0,0,16,5Zm0,19a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,16,24Zm5-8a5,5,0,1,1-5-5A5,5,0,0,1,21,16Z"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>
<script setup></script>

<script>
'1_1665005400';
export default {
    name: 'GuideProgram',
    props: {
        program: Object,
        channel: String,
        first: Boolean,
        startDate: Object,
        nextStart: Object,
        index: Number
    },
    data() {
        return {
            startTime: {},
            endTime: {},
            customWidth: '',
            startMargin: 0,
            gapToNext: 0
        };
    },
    methods: {
        correctTime(num) {
            return num < 10 ? '0' + num : num;
        },
        getMinDiff(startDate, endDate) {
            const msInMinute = 60 * 1000;

            return Math.round(Math.abs(endDate - startDate) / msInMinute);
        },
        calculateCustomWidth() {
            var self = this;
            let end = new Date(self.startTime);
            if (!self.program.end) {
                end.setMinutes(end.getMinutes() + self.program.duration);
            } else {
                end = new Date(self.program.end);
            }
            let actualDuration = this.getMinDiff(self.startDate, new Date(end));
            let actualWidth = actualDuration / 1.5 + 'rem';
            return actualWidth;
        },
        calculateStartMargin() {
            let self = this;
            let actualMargin = this.getMinDiff(
                self.startDate,
                new Date(self.program.start)
            );
            return actualMargin / 1.5 + 'rem';
        },
        calculateNextMargin() {
            let calculateEnd = new Date(this.program.start);
            calculateEnd.setMinutes(
                calculateEnd.getMinutes() + this.program.duration
            );

            let programEnd = this.program.end
                ? new Date(this.program.end)
                : new Date(calculateEnd);
            let minutesBetween = this.getMinDiff(programEnd, this.nextStart);
            if (minutesBetween > 0) {
                return minutesBetween / 1.5 + 'rem';
            } else {
                return 0;
            }
        }
    },
    beforeMount() {
        var self = this;
        this.startTime = new Date(self.program.start);
        this.endTime = new Date(self.program.end);
    },
    mounted() {}
};
</script>

<style lang="scss" scoped>
@import './variables.scss';
.guideProgramWrapper {
    flex-shrink: 0;
    padding: 0 5px;
    display: flex;
    justify-content: center;
    &.hidden {
        .guideProgram {
            display: none;
        }
    }
    .guideProgram {
        background-color: rgb(var(--secondaryBackground));
        // border-right: 1px solid lighten($color: $primaryBackground, $amount: 30);
        width: 100%;
        padding: 10px;
        position: relative;
        height: 100%;
        cursor: pointer;
        border-radius: 10px;
        display: inline-block;
        transition: transform 0.1s linear, box-shadow 0.1s linear;
        min-width: 100%;
        transition: background-color;
        transition-duration: 0.15s;
        transition-timing-function: ease;
        & > * {
            transition: color;
            transition-timing-function: ease;
            transition-duration: 0.15s;
        }
        &.disabled {
            transform: scale(1) !important;
            background-color: rgba(var(--secondaryBackground), 0.5);
            opacity: 0.7;
            pointer-events: none;
            cursor: default;
        }
        &:not(.disabled):hover {
            box-shadow: $defaultShadow;
            background-color: rgb(var(--primaryTextColor));
            min-width: max-content;
            z-index: 1;
            .programTitle {
                color: rgb(var(--primaryBackground));
            }
            .duration {
                color: rgb(var(--secondaryBackground));
            }
            .genres {
                color: rgb(var(--secondaryBackground));
            }
        }

        .programTitle {
            font: $primaryHeading-small;
            color: rgb(var(--primaryTextColor));
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
            display: -webkit-inline-box;
            position: sticky;
            left: 150px;
        }
        .duration {
            font: $secondaryHeading-small;
            color: rgb(var(--secondaryTextColor));
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
            margin-bottom: 5px;
            display: -webkit-inline-box;
            position: sticky;
            left: 150px;
        }
        .genres {
            font: $secondaryHeading-small;
            color: rgb(var(--secondaryTextColor));
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-inline-box;
            position: sticky;
            left: 150px;
            margin-top: auto;
        }

        .programInfo {
            position: absolute;
            bottom: 5px;
            right: 5px;
            padding: 5px;
        }
        .markers {
            position: absolute;
            bottom: 5px;
            right: 10px;
            .marker {
                height: 20px;
                width: 20px;
                &.recording {
                    path {
                        fill: red;
                    }
                }
                &.watchlist {
                    path {
                        fill: rgb(var(--themeColor));
                    }
                }
            }
        }
    }
    &:first-of-type {
        padding: 0 5px 0 0;
    }
}
</style>
