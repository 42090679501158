<template>
    <Transition>
        <div class="playerMenu channelGuide" v-if="channelList?.length > 0">
            <div class="channelGrid">
                <ChannelCard
                    v-for="channel in channelList"
                    :key="channel._id"
                    :channel="channel"
                    :currentChannel="currentChannel"
                    :onSelect="onSelect"
                />
            </div>
        </div>
    </Transition>
</template>

<script>
import ChannelCard from '@/components/ChannelCard.vue';
export default {
    name: 'ChannelGuide',
    props: {
        currentChannel: String,
        onSelect: Function,
        channelList: Array
    },
    components: {
        ChannelCard
    },
    methods: {},
    mounted() {
        let selectedChannel = document.querySelector('.channelCard.selected');
        let channelGrid = document.querySelector('.channelGrid');
        if (channelGrid && selectedChannel) {
            let topOffset = selectedChannel.offsetTop;
            channelGrid.scrollTop = topOffset - 200;
        }
    }
};
</script>

<style lang="scss" scoped>
@import './variables.scss';
.channelGuide {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 40rem;
    .heading {
        position: absolute;
        z-index: 99;
    }
}
.channelGrid {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 40rem;
    gap: 10px;
    position: relative;
    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
