import { nextTick, reactive } from "vue";
import emitter from 'tiny-emitter/instance';
import { Player } from "bitmovin-player";
import { SubtitleOverlay, UIContainer, UIManager } from "bitmovin-player-ui";
import message from "./statusMessage";
import axios from "axios";
import store from "@/store";
const playerController = reactive({
    playingEpg: null,
    channel: {},
    live: false,
    uiManager: null,
    playing: false,
    playMode: 'channel',
    streamId: '',
    isPlaying: false,
    loading: true,
    active: false,
    playerUi: false,
    channelPrograms: [],
    statusTimer: null
});
export default playerController;
export var player = null;

export async function initPlayer(program) {
    playerController.active = true;
    nextTick(() => {
        let poster = "";
        if (program && program.imageIds) {
            poster = getPoster(program);
            playerController.playingEpg = program;
        }
        return new Promise((resolve, reject) => {
            if (!playerController.player) {
                try {
                    const playerContainer = document.getElementById('playerSpace');
                    console.log(playerContainer);
                    const config = defaultConfig;
                    config.poster = poster,
                    player = new Player(playerContainer, config);
                    const simpleUI = new UIContainer({
                        components: [new SubtitleOverlay()]
                    });
                    playerController.uiManager = new UIManager(player, simpleUI);
                    resolve();
                } catch(err) {
                    reject(err);
                }
    
            } else {
                resolve();
            }
        })
    })
}
function getPoster(program) {
    if (!program) return '';
    return `https://image.powernet.tv/${program.imageIds?.[0]}`;
}
function getStreamToken(string) {
    return string.split('token=')[1].split('&')[0]
}

export async function load(streamurl, epg, playMode) {
    const stream = await axios.get(streamurl);
    const streamData = stream.data;
    return new Promise((resolve, reject) => {

        const type = ['safari', 'Safari'].includes(detectBrowser())
                        ? 'HLS'
                        : 'DASH';
        getContentIdForHLS(streamData.hls)
        .then(
            (hlsContentId) => {
                const streamToken = getStreamToken(type == 'HLS' ? streamData.hls : streamData.dash);
                console.log(streamData);
                var source = {
                    poster: `https://image.powernet.tv/${epg.imageIds?.[0]}`,
                    title: epg.title,
                    description: epg.description,
                    
                    drm: {
                        widevine: {
                            LA_URL: ''
                        },
                        playready: {
                            LA_URL: ''
                        },
                        fairplay: '',
                        preferredKeySystems: ['widevine'],
                        mediaKeySystemConfig: {
                            persistentState: 'required',
                            sessionTypes: ['persistent-license']
                        }
                    }
                };
                if (streamData.drm.widevineLicenseUrl) {
                    source.drm.widevine.LA_URL = streamData.drm.widevineLicenseUrl.replace(
                        'TOKEN',
                        streamToken
                    )
                }
                if (streamData.drm.playreadyLicenseUrl) {
                    source.drm.playready.LA_URL = streamData.drm.playreadyLicenseUrl.replace(
                        'TOKEN',
                        streamToken
                    )
                }
                if (streamData.drm.fairplay) {
                    source.drm.fairplay = getFairplayDrm(
                        hlsContentId,
                        streamData,
                        streamToken
                    )
                }
                if (type == 'DASH') {
                    let start = new Date(epg.start);
                    if (playMode == 'channel') {
                        source.dash = streamData.dash.replace(
                            'index.mpd',
                            `index-${
                                parseInt(start.getTime()) / 1000
                            }-now.mpd`
                        );
                    } else {
                        source.dash = streamData.dash;
                    }
                }
                if (type == 'HLS') {
                    let start = new Date(epg.start);
                    if (playMode == 'channel') {
                        source.hls =
                        streamData.hls.replace(
                                'index.m3u8',
                                `index-${
                                    parseInt(start.getTime()) /
                                    1000
                                }-now.m3u8`
                            ) + '&period=mono';
                    } else {
                        source.hls = streamData.hls;
                    }
                    source.hls += '&period=mono';
                }
                player.load(source).then(() => {
                    resolve(source);
                })
                .catch(err => {
                    reject(err);
                });
            }
        )
    })
}
function detectBrowser() {
    if (
        (navigator.userAgent.indexOf('Opera') ||
            navigator.userAgent.indexOf('OPR')) != -1
    ) {
        return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
        return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
        return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
        return 'Firefox';
    } else if (
        navigator.userAgent.indexOf('MSIE') != -1 ||
        !!document.documentMode == true
    ) {
        return 'IE'; //crap
    } else {
        return 'Unknown';
    }
}
function getFairplayDrm(hlsContentId, data, streamToken) {
    return {
        LA_URL:
            'https://fps.ezdrm.com/api/licenses/' +
            hlsContentId +
            '?streamToken=' +
            streamToken,
        certificateURL: data.drm.fairplayCertificateUrl,
        prepareContentId: (contentId) => {
            let uri = contentId;
            let uriParts = uri.split('://', 1);
            let protocol = uriParts[0].slice(-3);
            uriParts = uri.split(';', 2);
            contentId = uriParts.length > 1 ? uriParts[1] : '';
            uriParts = contentId.split('?', 2);
            contentId = uriParts.length > 1 ? uriParts[0] : contentId;
            return protocol.toLowerCase() == 'skd' ? contentId : '';
        },
        prepareLicenseAsync: (ckc) => {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.addEventListener('loadend', () => {
                    resolve(new Uint8Array(reader.result));
                });
                reader.addEventListener('error', () => {
                    reject(reader.error);
                });
                reader.readAsArrayBuffer(ckc);
            });
        },
        prepareMessage: (event) => {
            // eslint-disable-line
            return new Blob([event.message], {
                type: 'application/octet-binary'
            });
        },
        headers: {
            'content-type': 'application/octet-stream'
        },
        useUint16InitData: true,
        licenseResponseType: 'blob'
    };
}
function getContentIdForHLS(url) {
    if (
        !['safari', 'ios'].includes(detectBrowser().toLowerCase())
    ) {
        return Promise.resolve('');
    }
    return axios
        .get(url)
        .then((res) => {
            const manifest = res.data;
            if (manifest.includes('#EXT-X-KEY:')) {
                return res;
            } else {
                if (manifest.includes('tracks-')) {
                    const url2 = manifest
                        .split('\n')
                        .filter((o) => o.startsWith('tracks-'))[0];
                    if (!url2) {
                        throw new Error();
                    }
                    const newUrl =
                        url.substr(0, url.lastIndexOf('/')) +
                        '/' +
                        url2;
                    return axios.get(newUrl);
                } else {
                    throw new Error();
                }
            }
        })
        .then((res) => {
            const contentId = res.data
                .split('\n')
                .filter((o) => o.startsWith('#EXT-X-KEY:'))[0]
                .split('URI=')[1]
                .split('"')[1]
                .split(';')[1];
            return contentId;
        })
        .catch(() => {
            return '';
        });
}
export function setPlayingEpg(epg) {
    playerController.playingEpg = epg;
}
export function startStatus() {
    console.log("runs startstatus");
    let isLive = playerController.live;
    if (playerController.statusTimer) {
        clearInterval(playerController.statusTimer);
        playerController.statusTimer = null;
    }
    if (!playerController.playingEpg) return;
    console.log("statusTimer");
    playerController.statusTimer = setInterval(() => {
        let startTime = new Date(
            playerController.playingEpg.start
        ).getTime();
        if (isLive) {
            let position = Math.floor(
                (player.getCurrentTime() * 1000 - startTime) / 1000
            );
            sendStatus(position);
            this.updatePlayerData();
        } else {
            let position = player.getCurrentTime();
            sendStatus(position);
        }
    }, 10000);
}
export async function sendStatus(_position) {
    const position = Math.floor(_position);
    const token = localStorage.getItem('token');
    const profile = localStorage.getItem('lastProfile');
    const epgId = playerController.playingEpg._id;
    if (!position || !epgId || !token) return;
    const url = `${
        store.state.apiUrl
    }status/${epgId}?token=${token}&position=${Math.floor(position)}${
        profile ? `&profile=${profile}` : ''
    }`;
    axios.get(url)
}

export async function playFromEpg(epgId, { playMode, startOver, position }) {
    playerController.loading = true;
    try {
        const epg = await getEpg(epgId);
        if (!player) {
            await initPlayer(epg);
        }
        const channel = await getChannel(epg.channelId);
        const programList = await getProgramList(channel._id);
        if (!programList) {
            playerController.channelPrograms = [];
        } else {
            playerController.channelPrograms = programList;
        }
        return new Promise((resolve, reject) => {
            const streamurl = generateStreamUrl(playMode, epg._id, startOver ? true : false);
            load(streamurl, epg, playMode)
            .then(source => {
                player.play(source)
                .then(() => {
                    if (position > 10 && !startOver) {
                        player.seek(position);
                    }
                    playerController.playMode = playMode;
                    playerController.playingEpg = epg;
                    playerController.channel = channel;
                    playerController.live = false;
                    playerController.streamId = epg._id;
                    playerController.loading = false;
                    startStatus();
                    resolve();
                })
            })
            .catch(err => {
                console.error(err);
                playerController.loading = false;
                reject();
            })
            if (startOver) {
                
                return;
            }

        });
    } catch(err) {
        console.error(err);
        message.Error('Der skete en fejl under afspilning af programmet');
        playerController.loading = false;
        return;
    }
    
}
function twoDigit(num) {
    return num > 10 ? num : '0'+num;
}
function getPeriodForProgramList() {
    let today = new Date();
    today = new Date(today.setDate(today.getDate() - 6));
    let endDate = new Date();
    endDate = new Date(endDate.setDate(endDate.getDate() + 1));
    today.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    return {
        from: `${today.getFullYear()}-${twoDigit(
            today.getMonth() + 1
        )}-${twoDigit(today.getDate())}`,
        to: `${endDate.getFullYear()}-${twoDigit(
            endDate.getMonth() + 1
        )}-${twoDigit(endDate.getDate())}`
    };
}
async function getProgramList(channelId) {
    let storage = window.localStorage;
    let profile = storage.getItem('lastProfile');
    let token = storage.getItem('token');
    const period = getPeriodForProgramList();
    return new Promise((resolve, reject) => {
        if (!token || !profile) {
            reject({
                message: 'no profile or no token'
            });
            return;
        }
        axios
            .get(`${store.state.apiUrl}channel?channel=${channelId}&token=${token}&profile=${profile}&epgMode=PERIOD&from=${period.from}&to=${period.to}`)
            .then((res) => {
                if (!res.data) {
                    reject({
                        message: 'no data'
                    });
                    return;
                }
                let channel = res.data[0];
                if (channel) {
                    resolve(channel.epg);
                } else {
                    reject({
                        message: 'couldnt find channel'
                    });
                }
            });
    });
}
export async function playFromChannel(channelId) {
    playerController.loading = true;
    playerController.active = true;
    try {
        const channel = await getChannel(channelId);
        const liveEpg = await getLiveEpgFromChannel(channel);
        const programList = await getProgramList(channelId);
        if (!programList) {
            playerController.channelPrograms = [];
        } else {
            playerController.channelPrograms = programList;
        }
        if (!player) {
            await initPlayer();
        }
        playerController.playingEpg = liveEpg;
        return new Promise((resolve, reject) => {
            try {
                load(generateStreamUrl('channel', channelId), liveEpg, 'channel')
                .then(source => {
                    player.play(source).then(() => {
                        playerController.channel = channel;
                        playerController.live = true;
                        playerController.streamId = channelId;
                        playerController.playMode = 'channel';
                        player.timeShift(0);
                        playerController.loading = false;
                        startStatus();
                        resolve();
                    });
                }).catch(err => {
                    console.error(err);
                    message.Error('Der skete en fejl');
                    reject();
                });
            } catch(err) {
                reject(err);
            }
        })
    } catch(err) {
        console.error(err);
    }
}

function generateStreamUrl(playMode, streamid) {
    const apiUrl = store.state.apiUrl;
    const token = localStorage.getItem('token');
    const profile = localStorage.getItem('lastProfile');

    const type = ['safari', 'Safari'].includes(detectBrowser())
        ? 'HLS'
        : 'DASH';
    return `${apiUrl}${playMode}/${streamid}/play?token=${token}&profile=${profile}&mode=${type}`;
}
function clearPlayer() {
    playerController.playingEpg = null;
    playerController.channel = {};
    playerController.live = false;
    playerController.uiManager = null;
    playerController.playing = false;
    playerController.playMode = 'channel';
    playerController.streamId = '';
    playerController.isPlaying = false;
    playerController.loading = false;
    player = null;
    playerController.active = false;
}
export async function stopPlayer(destroy) {
    emitter.emit('stopArchiveTimer');
    return new Promise((resolve) => {
        if (!playerController.streamId) {
            clearPlayer();
            return resolve();
        }
        const stopurl = generateStopUrl(playerController.playMode, playerController.streamId);
        axios.get(stopurl)
        .then(() => {
            if (destroy) {
                player.destroy().then(() => {
                    clearPlayer();
                    resolve();
                    return;
                })
                .catch(err => {
                    console.error(err);
                    resolve();
                    return;
                });
            }
            resolve();
        })
        .catch(err => {
            console.error(err);
            resolve();
        });
    });


    function generateStopUrl(mode, id) {
        const token = localStorage.getItem('token');
        const profile = localStorage.getItem('lastProfile');

        return `${store.state.apiUrl}${mode}/${id}/stop?token=${token}&${profile}`;
    }
}

export async function getChannel(channelId) {
    const channels = store.state.channelList;
    return new Promise((resolve, reject) => {
        if (channels) {
            const channel = channels.find(o => o._id == channelId || o.baseChannel == channelId);
            resolve(channel);
            return;
        }
        const token = localStorage.getItem('token');
        const profile = localStorage.getItem('lastProfile');
        const url = `${store.state.apiUrl}channel?token=${token}&profile=${profile}&channel=${channelId}&epgMode=NOW`;
        axios.get(url)
            .then(res => {
                if (res.data && res.data[0]) {
                    resolve(res.data[0]);
                }
                reject();
            })
    })
}
export async function getEpg(epgId) {
    const token = localStorage.getItem('token');
    const profile = localStorage.getItem('lastProfile');
    const url = `${store.state.apiUrl}epg/${epgId}?token=${token}&profile=${profile}`;
    return axios.get(url).then(res => res.data);
}

function onPlay() {
    playerController.playing = true;
}
function onPause() {
    playerController.playing = false;

}

function getLiveEpgFromChannel(channel) {
    const programs = channel.epg;
    if (!programs) return null;
    const now = new Date();
    const liveProgram = programs.find(o => {
        return new Date(o.start) < now && new Date(o.end) > now;
    })
    if (liveProgram) return liveProgram;
    return null;
}

const defaultConfig = {
    key: '4D40D33E-A3A4-491C-ABD1-A94AAD4C733A',
    playback: {
        muted: false,
        autostart: true
    },
    metadata: {
        poster: ''
    },
    events: {
        playing: () => {
            onPlay();
        },
        paused: () => {
            onPause();
        },
        timechanged: () => {
            emitter.emit('videoProgress');
        },
        sourceloaded: () => {
            emitter.emit('initPlayerUi');
        },
        error: (err) => {
            console.log(err);
            message.Error('Der skete en fejl under afspilning af programmet');
            stopPlayer(true);
        },
        castavailable: (e) => {
            if (e.receiverAvailable) {
                emitter.emit('castAvailable', true);
            } else {
                emitter.emit('castAvailable', false);
            }
        },
        airplayavailable: () => {
            emitter.emit('player_airplayAvailable');
        }
    },
    remotecontrol: {
        type: 'googlecast',
        receiverApplicationId: 'C44861CA'
    },
    ui: false
};






// metadata: {
//     title: epg.title,
//     subtitle: epg.subtitle,
//     start: new Date(
//         epg.start
//     ).getTime(),
//     duration: epg.duration * 60,
//     streamType:
//         self.programMode == 'channel'
//             ? 'LIVE'
//             : 'BUFFERED',
//     token: window.localStorage.getItem(
//         'token'
//     ),
//     mode:
//         self.programMode == 'channel'
//             ? 'LIVE'
//             : 'archive',
//     channelName: self.playingChannel?.name,
//     channelLogo: self.playingChannel?.logo,
//     images: [
//         {
//             url: `https://image.powernet.tv/${program.imageIds?.[0]}?width=1777&height=1000`,
//             width: 1777,
//             height: 1000
//         }
//     ]
// },