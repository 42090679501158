<template>
    <div class="programInfo playerMenu">
        <div :class="`poster ${!secondaryPoster ? 'onlyPrimary' : ''}`">
            <div
                :class="`programLock ${program.favorite ? 'locked' : ''}`"
                v-if="playMode == 'recording' || program.recording"
                @click="
                    () => {
                        lockProgram();
                    }
                "
            >
                <svg
                    v-if="program.favorite"
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="lock"
                        d="M25,12H24V9a5.00588,5.00588,0,0,0-5-5H13A5.00588,5.00588,0,0,0,8,9v3H7a2.00591,2.00591,0,0,0-2,2V26a2.00591,2.00591,0,0,0,2,2H25a2.00591,2.00591,0,0,0,2-2V14A2.00591,2.00591,0,0,0,25,12Zm-8,8.7226V23.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V20.7226a2,2,0,1,1,2,0ZM20,12H12V9a1.01348,1.01348,0,0,1,1-1h6a1.01348,1.01348,0,0,1,1,1Z"
                    />
                </svg>
                <svg
                    v-else
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="lock-open"
                        d="M25,12H24V5a5.00588,5.00588,0,0,0-5-5H13A5.00588,5.00588,0,0,0,8,5V7h4V5a1.01348,1.01348,0,0,1,1-1h6a1.01348,1.01348,0,0,1,1,1v7H7a2.00591,2.00591,0,0,0-2,2V26a2.00591,2.00591,0,0,0,2,2H25a2.00591,2.00591,0,0,0,2-2V14A2.00591,2.00591,0,0,0,25,12Zm-8,8.7226V23.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V20.7226a2,2,0,1,1,2,0Z"
                    />
                </svg>
            </div>
            <div class="closeMenu" @click="exit">
                <svg
                    id="glyphicons-basic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                >
                    <path
                        id="times"
                        d="M20.24249,16l5.65692,5.65674-4.24218,4.24267L16,20.24237l-5.65723,5.657L6.10059,21.65674,11.75751,16,6.10059,10.34326l4.24218-4.24267L16,11.75763l5.65723-5.657,4.24218,4.24267Z"
                    />
                </svg>
            </div>
            <img class="posterImg" :src="secondaryPoster || poster" alt="" />
            <div class="posterContent">
                <h1 class="programTitle">
                    {{ program.title }}
                </h1>
                <p class="subLine">{{ program.subTitle }} {{ episodeLabel }}</p>
                <ProgressBar
                    class="progressBar"
                    v-if="program.position > 10"
                    :customWidth="`${program.position / program.duration}`"
                />
            </div>
        </div>
        <div class="programInfoInner">
            <div
                :class="{
                    actionButtons: true,
                    startOverHidden: !showStartOver(program)
                }"
            >
                <button
                    v-if="showStartOver(program)"
                    class="actionButton startOver"
                    @click="
                        () => {
                            playEvent(true);
                        }
                    "
                >
                    <svg
                        id="glyphicons-basic"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                    >
                        <path
                            id="skip-to-previous"
                            d="M22.99933,9.46185V22.53821a.8.8,0,0,1-1.2312.67383L11.55219,16.67383a.8.8,0,0,1,0-1.34766L21.76813,8.788A.8.8,0,0,1,22.99933,9.46185ZM10,8H9A1,1,0,0,0,8,9V23a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V9A1,1,0,0,0,10,8Z"
                        />
                    </svg>
                    Se forfra
                </button>
                <button
                    v-if="playable"
                    :class="
                        playerController.playingEpg?._id == program._id &&
                        playerController.playing
                            ? 'actionButton play playing'
                            : 'actionButton play'
                    "
                    @click="
                        () => {
                            //eslint-disable-next-line
                            playEvent(false);
                        }
                    "
                >
                    <svg
                        v-if="
                            playerController.playing &&
                            playerController.playingEpg &&
                            playerController.playingEpg._id == program._id
                        "
                        id="glyphicons-basic"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                    >
                        <path
                            id="pause"
                            d="M14,7V25a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V7A1,1,0,0,1,8,6h5A1,1,0,0,1,14,7Zm9-1H18a1,1,0,0,0-1,1V25a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V7A1,1,0,0,0,23,6Z"
                        />
                    </svg>
                    <svg
                        v-else
                        id="glyphicons-basic"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                    >
                        <path
                            id="play"
                            d="M24.8175,16.86432,9.503,25.77667A1,1,0,0,1,8,24.91235V7.08765a1,1,0,0,1,1.503-.86432L24.8175,15.13568A1.00006,1.00006,0,0,1,24.8175,16.86432Z"
                        />
                    </svg>
                    {{ playLabel }}</button
                ><button
                    :class="`actionButton list ${
                        program.watchList ? 'onList' : ''
                    }`"
                    @click="
                        () => {
                            handleList(
                                program,
                                program.watchList ? 'delete' : 'add'
                            );
                        }
                    "
                >
                    <svg
                        v-if="program.watchList"
                        id="glyphicons-basic"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                    >
                        <path
                            id="star"
                            d="M27.34766,14.17944l-6.39209,4.64307,2.43744,7.506a.65414.65414,0,0,1-.62238.85632.643.643,0,0,1-.38086-.12744l-6.38568-4.6383-6.38574,4.6383a.643.643,0,0,1-.38086.12744.65419.65419,0,0,1-.62238-.85632l2.43744-7.506L4.66046,14.17944A.65194.65194,0,0,1,5.04358,13h7.89978L15.384,5.48438a.652.652,0,0,1,1.24018,0L19.06476,13h7.89978A.652.652,0,0,1,27.34766,14.17944Z"
                        />
                    </svg>
                    <svg
                        v-else
                        id="glyphicons-basic"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                    >
                        <path
                            id="star-empty"
                            d="M26.96454,13H19.06476L16.62415,5.48438a.652.652,0,0,0-1.24018,0L12.94336,13H5.04358a.65194.65194,0,0,0-.38312,1.17944l6.39209,4.64307-2.43744,7.506a.65419.65419,0,0,0,.62238.85632.643.643,0,0,0,.38086-.12744l6.38574-4.6383,6.38568,4.6383a.643.643,0,0,0,.38086.12744.65414.65414,0,0,0,.62238-.85632l-2.43744-7.506,6.39209-4.64307A.652.652,0,0,0,26.96454,13Zm-7.36066,3.96143-1.352.98193.51612,1.58935.8717,2.68409-2.28394-1.65869-1.35168-.98194-1.35169.98194L12.36847,22.2168l.8717-2.68409.51606-1.58935-1.352-.98193-2.2873-1.66163h4.49775l.51623-1.58935.87317-2.689.87317,2.689.51623,1.58935h4.49768Z"
                        />
                    </svg>
                    Min liste</button
                ><button
                    v-if="canRecord()"
                    :class="{
                        actionButton: true,
                        record: true,
                        recording:
                            typeof program.recording != 'undefined'
                                ? program.recording
                                : mode == 'recording'
                                ? true
                                : false
                    }"
                    @click="
                        () => {
                            handleRecord(
                                typeof program.recording != 'undefined'
                                    ? program.recording
                                        ? 'delete'
                                        : 'add'
                                    : mode == 'recording'
                                    ? 'delete'
                                    : 'add'
                            );
                        }
                    "
                >
                    <svg
                        id="glyphicons-basic"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                    >
                        <path
                            id="voicemail"
                            d="M23,10a5.98922,5.98922,0,0,0-5.18823,9H14.18823A5.995,5.995,0,1,0,9,22H23a6,6,0,0,0,0-12ZM9,19a3,3,0,1,1,3-3A3.00328,3.00328,0,0,1,9,19Zm14,0a3,3,0,1,1,3-3A3.00328,3.00328,0,0,1,23,19Z"
                        />
                    </svg>
                    {{
                        typeof program.recording != 'undefined'
                            ? program.recording
                                ? 'Annuller optagelse'
                                : 'Optag'
                            : mode == 'recording'
                            ? 'Annuller optagelse'
                            : 'Optag'
                    }}
                </button>
            </div>
            <SettingCategory
                v-if="channelDataAvailable"
                :style="{ marginBottom: '15px' }"
            >
                <div class="sentCard">
                    <img :src="channelLogo" alt="" class="channelLogo" />
                    <div class="sentContent">
                        <h3 class="channelName">{{ channelName }}</h3>
                        <p class="programStart">
                            {{ startTime }} - {{ endTime }}
                        </p>
                    </div>
                </div>
            </SettingCategory>
            <p class="description">{{ program.description }}</p>
            <SettingCategory
                :heading="`Andre kanaler hvor du kan se ${program.title}`"
                :style="{ margin: '20px 0' }"
                v-if="
                    program.epgList &&
                    filteredEpgList(program.epgList).length > 0
                "
            >
                <div class="services channels">
                    <EpgListItem
                        v-for="service in filteredEpgList(program.epgList)"
                        :key="service._id"
                        :info="{
                            name: service.channelName,
                            logo: service.channelLogo,
                            start: service.start
                        }"
                        @click="
                            () => {
                                if (new Date() > new Date(service.start)) {
                                    playEvent(false, service._id);
                                }
                            }
                        "
                    />
                </div>
            </SettingCategory>
            <SettingCategory
                heading="Streaming tjenester"
                :description="`Andre tjenester hvor du kan se ${program.title}`"
                :style="{ margin: '20px 0' }"
                v-if="program.streams && program.streams.length > 0"
            >
                <div class="services">
                    <EpgListItem
                        :info="{
                            name: streamingService.name,
                            logo: streamingService.logo,
                            start: null
                        }"
                        v-for="streamingService in program.streams"
                        :key="streamingService._id"
                        @click="
                            () => {
                                openLink(streamingService.url);
                            }
                        "
                    />
                </div>
            </SettingCategory>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import message from '@/components/statusMessage';
import SettingCategory from '@/components/SettingCategory.vue';
import EpgListItem from '@/components/EpgListItem.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import playerController, { player } from './PlayerController';
export default {
    name: 'ProgramInfo',
    props: {
        onPlay: Function,
        programContext: String, // program or epg
        program: Object,
        exit: Function,
        onRecord: Function
    },
    data() {
        return {
            canPlay: true,
            playerController
        };
    },

    components: {
        SettingCategory,
        EpgListItem,
        ProgressBar
    },
    computed: {
        poster() {
            const program = this.program;
            if (!program) return './img/placeholder_img.png';
            const images = program.imageIds;
            if (!images || !images.length) return './img/placeholder_img.png';
            return `https://image.powernet.tv/${images[0]}?height=250`;
        },
        secondaryPoster() {
            const program = this.program;
            if (!program) return null;
            const images = program.imageIds;
            if (!images) return null;
            if (images.length < 2) {
                return null;
            }
            return `https://image.powernet.tv/${images[1]}?height=250`;
        },
        episodeLabel() {
            const series = this.program.series;
            if (!series) {
                return '';
            }
            let episode = series.episode || null;
            let season = series.season || null;
            if (season && episode) {
                return `S${season} E${episode}`;
            } else if (season && !episode) {
                return `S${season}`;
            } else if (!season && episode) {
                return `E${episode}`;
            }
            return '';
        },
        playLabel() {
            const program = this.program;
            if (this.playingId == program._id) {
                if (this.playing) {
                    return 'Pause';
                } else {
                    if (program.position > 10) {
                        return 'Se videre';
                    } else {
                        return 'Afspil';
                    }
                }
            }
            if (program.position > 10) {
                return 'Se videre';
            }
            return 'Afspil';
        },
        channelLogo() {
            let program = this.program;
            if (this.programContext == 'program') {
                program = this.program.epgList?.[0];
            }
            if (!program) {
                console.error('No programData');
                return '';
            }
            let logo = program.channelLogo;
            return logo;
        },
        channelName() {
            let program = this.program;
            if (this.programContext == 'program') {
                program = this.program.epgList?.[0];
            }
            if (!program) {
                console.error('No programData');
                return '';
            }
            let channelName = program.channelName;
            return channelName;
        },
        channelDataAvailable() {
            const program =
                this.programContext == 'program'
                    ? this.program.epgList?.[0]
                    : this.program;
            if (!program) return false;
            return program.channelLogo && program.channelName && program.start;
        },
        startTime() {
            let program = this.program;
            if (this.programContext == 'program') {
                program = this.program.epgList?.[0];
            }
            let timeLabel = this.getTimeLabel(program.start);
            return timeLabel;
        },
        endTime() {
            let program = this.program;
            if (this.programContext == 'program') {
                program = this.program.epgList?.[0];
            }
            const end = new Date(program.end);
            return `${this.twoDigit(end.getHours())}.${this.twoDigit(
                end.getMinutes()
            )}`;
        },
        playable() {
            const program = this.program;
            if (this.programContext == 'program') {
                const epgList = program.epgList;
                if (!epgList || epgList.length < 1) return false;
                const playableEpgs = epgList.filter((o) => {
                    if (new Date(o.start) < new Date()) return o;
                });
                if (!playableEpgs || playableEpgs.length < 1) return false;
                return true;
            }
            const start = new Date(program.start);
            if (start < new Date()) {
                return true;
            }
            return false;
        }
    },
    methods: {
        playEvent(startover, epgid) {
            if (
                playerController.playingEpg &&
                playerController.playingEpg._id == this.program._id
            ) {
                if (playerController.playing) {
                    player.pause();
                } else {
                    player.play();
                }
                return;
            }
            if (this.onPlay && typeof this.onPlay == 'function') {
                this.onPlay(startover, epgid);
            }
        },
        openLink(url) {
            window.open(url, '_blank');
        },
        correctEpgList() {
            let epgList = this.program.epgList;
            let newChannelList = [];
            let channelList = [...this.$store.state.channelList];
            epgList.forEach((item) => {
                let channel = channelList.find((o) => {
                    return o.baseChannel == item.channelId;
                });
                newChannelList.push({ ...channel, ...item });
            });
        },
        playpause() {
            let program = this.program;
            if (
                new Date(program.start) < new Date() &&
                new Date(program.end) > new Date() &&
                !program.recording
            ) {
                this.onPlayProgram(program, 'channel', false);
                return;
            } else {
                const newMode = program.recording ? 'recording' : 'archive';
                this.onPlayProgram(program, newMode, false);
            }
        },
        canRecord() {
            if (!this.$store.state.user.hasnPvr) {
                return false;
            } else {
                console.log('User has npvr');
            }
            if (this.program.recording) {
                return true;
            }
            if (this.programContext == 'program') {
                if (!this.program.epgList) {
                    return false;
                }
                if (this.program.epgList.length < 1) {
                    return false;
                }
            } else {
                if (new Date(this.program.availableUntil) < new Date()) {
                    return false;
                }
            }
            return true;
        },
        handleList(program, mode) {
            console.log(program);
            let localStorage = window.localStorage;
            let token = localStorage.getItem('token');
            let profile = localStorage.getItem('lastProfile');
            let dataUrl = `${this.$store.state.apiUrl}watchlist/${profile}/${
                program.programId ? program.programId : program._id
            }?token=${token}`;
            if (mode == 'add') {
                axios
                    .post(
                        dataUrl,
                        {},
                        {
                            'Content-Type': 'application/json'
                        }
                    )
                    .then(() => {
                        this.onList(true);
                    })
                    .catch((err) => {
                        console.error(err);
                        message.Error(
                            'Kunne ikke tilføje programmet til din liste'
                        );
                    });
            } else if (mode == 'delete') {
                axios
                    .delete(dataUrl, {
                        'Content-Type': 'application/json'
                    })
                    .then(() => {
                        this.onList(false);
                    })
                    .catch((err) => {
                        console.error(err);
                        message.Error(
                            'Kunne ikke fjerne programmet fra din liste'
                        );
                    });
            }
        },
        getProfileForRecording(mode) {
            if (mode == 'delete' && this.playMode == 'recording') {
                return this.program.profile ? this.program.profile._id : null;
            } else {
                return this.$store.state.user.profile._id
                    ? this.$store.state.user.profile._id
                    : null;
            }
        },
        showStartOver(program) {
            if (program.position > 10 && this.playMode != 'channel') {
                return true;
            }
            if (this.playMode == 'channel') {
                return true;
            }
            return false;
        },
        lockProgram() {
            let lockState = this.program.favorite;
            let token = window.localStorage.getItem('token');
            let currentProfile = window.localStorage.getItem('lastProfile');
            let profile = this.program.profile
                ? this.program.profile._id
                : currentProfile;
            let programId = this.program._id;
            if (!token) return;
            let dataUrl = `${
                this.$store.state.apiUrl
            }recording/${programId}/favorite?token=${token}&mode=${!lockState}${
                profile ? `&profile=${profile}` : ''
            }`;
            axios
                .get(dataUrl)
                .then(() => {
                    this.onLock(!lockState);
                })
                .catch((err) => {
                    console.error(err);
                    message.Error('Kunne ikke låse optagelsen');
                });
        },
        handleRecord(mode) {
            let self = this;
            let localStorage = window.localStorage;
            let profile = this.getProfileForRecording(mode);
            let dataUrl = `${this.$store.state.apiUrl}recording/${
                this.programContext == 'program'
                    ? self.program?.epgList?.[0]?._id
                    : self.program._id
            }/${mode}?token=${localStorage.getItem('token')}${
                profile ? `&profile=${profile}` : ''
            }`;
            axios
                .get(dataUrl)
                .then(({ data }) => {
                    if (data.success == true) {
                        self.program.recording = mode == 'add' ? true : false;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    message.Error('Kunne ikke starte optagelse');
                });
        },
        filteredEpgList(_epgList) {
            // return _epgList;
            let epgList = [..._epgList];
            epgList = epgList.sort((a, b) => {
                return new Date(a.start) - new Date(b.start);
            });
            epgList.splice(0, 1);
            return [...epgList];
        },
        getTimeLabel(_date) {
            let date = new Date(_date);
            let month = new Date(date).toLocaleString(
                window.navigator.language,
                { month: 'short' }
            );
            return `${this.twoDigit(
                date.getDate()
            )}. ${month}. ${date.getFullYear()} ${this.twoDigit(
                date.getHours()
            )}.${this.twoDigit(date.getMinutes())}`;
        },
        twoDigit(num) {
            return num < 10 ? `0${num}` : num;
        },
        getTriLine(program) {
            console.log(program);
        }
    }
};
</script>

<style lang="scss">
.player {
    .programInfo {
        background-color: rgba(var(--primaryBackground), 0.7);
    }
}
</style>
<style lang="scss" scoped>
@import '@/components/variables.scss';
.programInfo {
    width: 30rem;
    top: 30px;
    right: 30px;
    bottom: 30px;
    background-color: rgba(var(--primaryBackground), 0.9);
    border-radius: 20px;
    overflow: scroll;
    position: fixed;
    text-align: left;
    z-index: 9999;
    .poster {
        width: 100%;
        object-fit: cover;
        min-height: max-content;
        height: 250px;
        position: relative;
        overflow: hidden;
        display: flex;
        // flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        padding: 20px 20px 0 20px;
        & > * {
            position: relative;
            z-index: 2;
        }
        &.onlyPrimary {
            padding: 20px;
            &:after {
                content: '';
                height: 80%;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1;
                background: linear-gradient(
                    to top,
                    rgba(var(--primaryBackground), 0.9),
                    transparent
                );
            }
            .posterImg {
                position: absolute;
                width: 100%;
                max-width: 100%;
            }
        }
        .closeMenu {
            position: absolute;
            top: 10px;
            left: 10px;
            cursor: pointer;
            background-color: rgba(var(--secondaryBackground), 0.5);
            border-radius: 10px;
            padding: 5px;
            width: 45px;
            height: 45px;
            svg {
                height: 100%;
                width: 100%;
                path {
                    fill: rgb(var(--themeColor));
                }
            }
        }
        .programLock {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            background-color: rgba(var(--secondaryBackground), 0.5);
            border-radius: 10px;
            padding: 5px;
            width: 35px;
            height: 35px;
            &.locked {
                background-color: rgb(var(--themeColor));
                svg {
                    path {
                        fill: rgb(var(--primaryTextColor));
                    }
                }
            }
            svg {
                height: 100%;
                width: 100%;
                path {
                    fill: rgb(var(--themeColor));
                }
            }
        }
        .posterImg {
            display: block;
            object-fit: cover;
            width: auto;
            height: 100%;
            max-width: 170px;
            // position: absolute;
            top: 0;
            right: 0;
            z-index: unset;
            border-radius: 10px;
        }
        .posterContent {
            // padding: 10px 20px;
            width: 100%;
            height: max-content;
            .programTitle {
                display: inline;
                font-weight: bold;
            }
        }
    }
    .programInfoInner {
        padding-top: 10px;
        padding: 20px;
        .sentCard {
            display: flex;
            background-color: rgb(var(--secondaryBackground));
            border-radius: 10px;
            overflow: hidden;
            height: 70px;
            .sentContent {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 10px;
                .channelName {
                    font-size: 22px;
                }
                .programStart {
                    font-size: 15px;
                }
            }
            img.channelLogo {
                height: 100%;
                width: auto;
                background-color: white;
                border-radius: 10px;
                overflow: hidden;
            }
        }
    }
    .programEpisode {
        margin-left: 10px;
        color: rgb(var(--secondaryTextColor));
        font-size: 13px;
        font-weight: bold;
    }
    .actionButtons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'startOver play' 'list record';
        gap: 5px;
        width: 100%;
        flex-wrap: wrap;
        margin: 0 0 10px 0;
        &.startOverHidden {
            grid-template-areas: 'play play' 'list record';
        }
        button {
            height: 40px;
            border-radius: 5px;
            font-weight: bold;
            font-size: 14px;
            flex-shrink: 0;
            transition: background-color 0.1s linear, color 0.1s linear,
                box-shadow 0.1s ease;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            svg {
                height: 20px;
                width: 20px;
                path {
                    transition: fill 0.1s linear;
                    fill: rgb(var(--themeColor));
                }
            }
            &:hover {
                box-shadow: $defaultShadow;
            }
            &.play {
                background-color: rgb(var(--themeColor));
                color: rgb(var(--primaryTextColor));
                width: 100%;
                grid-area: play;
                &.playing {
                    background-color: rgb(var(--secondaryBackground));
                    color: rgb(var(--themeColor));
                    path {
                        fill: rgb(var(--themeColor));
                    }
                }
                path {
                    fill: rgb(var(--primaryTextColor));
                }
            }
            &.startOver {
                background-color: rgb(var(--themeColor));
                color: rgb(var(--primaryTextColor));
                grid-area: startOver;
            }
            &.list {
                background-color: rgb(var(--secondaryBackground));
                color: rgb(var(--themeColor));
                grid-area: list;
                &.onList {
                    background-color: rgb(var(--themeColor));
                    color: rgb(var(--primaryTextColor));
                    svg {
                        path {
                            fill: rgb(var(--primaryTextColor));
                        }
                    }
                }
            }
            &.record {
                background-color: rgb(var(--secondaryBackground));
                color: rgb(var(--themeColor));
                grid-area: record;
                svg {
                    path {
                        transition: fill 0.1s linear;
                        fill: rgb(var(--themeColor));
                    }
                }
                &.recording {
                    background-color: rgb(var(--themeColor));
                    color: rgb(var(--primaryTextColor));
                    svg {
                        path {
                            fill: rgb(var(--primaryTextColor));
                        }
                    }
                }
            }
        }
    }
    .services {
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        &.channels {
            img.serviceLogo {
                object-fit: contain;
            }
        }
    }
}
</style>
