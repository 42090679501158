<template>
    <div class="streaming">
        <header>
            <SearchMain
                :filters="true"
                :groupToggle="false"
                :onSearch="handleSearch"
                placeholder="Søg i streaming"
            />
        </header>
        <div class="streamingMain" v-if="!searching">
            <ContentCategory
                :title="service.name"
                :clickAction="sortSearch"
                :category="service._id"
                :key="service._id"
                v-for="service in streamingServices"
            >
                <ProgramCard
                    @click="() => (selectedProgram = program)"
                    :key="program._id"
                    v-for="program in service.programs"
                    :program="{
                        ...program,
                        thumbnail: `https://image.powernet.tv/${program.imageIds?.[0]}?width=400&height=200`
                    }"
                />
            </ContentCategory>
        </div>
        <TransitionGroup
            name="channels"
            tag="div"
            class="searchResults"
            v-if="searching && searchResults.length > 0"
        >
            <ProgramCard
                @click="
                    () => {
                        selectedProgram = program;
                    }
                "
                :key="program._id"
                v-for="program in filteredSearchResults"
                :program="{
                    ...program,
                    thumbnail: `https://image.powernet.tv/${program.imageIds?.[0]}?width=400&height=200`,
                    live: false,
                    mode: 'streaming'
                }"
            />
        </TransitionGroup>
        <h3 class="noResults" v-else-if="searching && searchResults.length < 1">
            Der blev ikke fundet nogen resultater
        </h3>
    </div>
    <transition>
        <program-info
            v-if="selectedProgram"
            :program="selectedProgram"
            :exit="
                () => {
                    selectedProgram = null;
                }
            "
            :onPlay="
                (startOver, epgid) => {
                    playSelected(startOver, epgid);
                }
            "
            :programContext="'program'"
        />
    </transition>
</template>

<script>
import ContentCategory from '../components/ContentCategory.vue';
import ProgramCard from '../components/programCard.vue';
import emitter from 'tiny-emitter/instance';
import message from '@/components/statusMessage';
import axios from 'axios';
import SearchMain from '@/components/Search.vue';
import ProgramInfo from '@/components/programInfo.vue';
import { playFromEpg } from '@/components/PlayerController';
export default {
    setup() {},
    name: 'StreamingMain',
    data() {
        return {
            archiveList: [],
            selectedProgram: null,
            searching: false,
            searchResults: [],
            searchTimer: null,
            streamingServices: {}
        };
    },
    computed: {
        filteredSearchResults() {
            const searchResults = this.searchResults;
            return searchResults.filter((o) => {
                if (o.epgList && o.epgList.length > 0) {
                    return o;
                }
                if (o.streams && o.streams.length > 0) {
                    return o;
                }
            });
        }
    },
    components: {
        ContentCategory,
        ProgramCard,
        SearchMain,
        ProgramInfo
    },
    mounted() {
        let self = this;
        emitter.on('profileChange', this.fetchStreaming);
        self.fetchStreaming();
        emitter.on('resetSearch', this.resetSearch);
    },
    beforeUnmount() {
        emitter.off('profileChange', this.fetchStreaming);
        emitter.off('resetSearch', this.resetSearch);
    },
    methods: {
        playSelected(startover, epgid) {
            let program = this.selectedProgram;
            if (epgid) {
                const epg = program.epgList.find((o) => o._id == epgid);
                if (epg) program = epg;
            } else if (program.epgList && program.epgList[0]) {
                program = program.epgList[0];
            } else {
                message.Error(
                    'der skete en fejl under afspilning af programmet'
                );
                this.selectedProgram = null;
                return;
            }
            this.selectedProgram = null;
            playFromEpg(program._id, {
                playMode: 'archive',
                startOver: startover
            });
        },
        sortSearch(category) {
            emitter.emit(`setservice`, category);
        },
        resetSearch() {
            this.searching = false;
            this.searchResults = [];
        },
        openPlayer(_program) {
            // let program = this.constructPlayableProgram(_program);
            console.log(_program);
            this.playerSetup = {
                autoplay: false,
                context: 'program',
                playMode: 'archive',
                defaultMenu: 'programInfo',
                defaultProgram: _program
            };
            this.playerActive = true;
        },
        closePlayer() {
            this.playerActive = false;
            this.selectedProgram = {};
            this.playerMode = '';
        },
        fetchStreaming() {
            let self = this;
            let localStorage = window.localStorage;
            let profile = localStorage.getItem('lastProfile');
            let dataUrl = `${
                this.$store.state.apiUrl
            }streaming/featured?token=${localStorage.getItem(
                'token'
            )}&profile=${profile}`;
            return axios
                .get(dataUrl)
                .then(({ data }) => {
                    if (!data) {
                        message.Error(
                            'Der skete en fejl under indlæsning af streaming'
                        );
                        return;
                    }
                    self.streamingServices = data.filter((o) => {
                        return o.programs.length > 0;
                    });
                })
                .catch(() => {
                    message.Error(
                        'Der skete en fejl under indlæsning af streaming'
                    );
                });
        },
        handleSearch(params) {
            if (
                params.keyword != '' ||
                params.genre != '' ||
                params.service != ''
            ) {
                this.searching = true;
            } else {
                this.searching = false;
                return;
            }
            let searchUrl = this.constructSearchUrl({
                filter: params.keyword,
                services: params.service,
                genres: params.genre
            });
            this.search(searchUrl);
        },
        constructSearchUrl({ filter, services, genres }) {
            let localStorage = window.localStorage;
            let token = localStorage.token;
            let profile = localStorage.lastProfile;
            let url = `${this.$store.state.apiUrl}program?token=${token}&profile=${profile}&mode=STREAMING`;
            if (filter) {
                url = url + `&filter=${filter}`;
            }
            if (services) {
                url = `${url}&streamingService=${services}`;
            }
            if (genres) {
                url = `${url}&genre=${genres}`;
            }
            return url;
        },
        constructPlayableProgram(program) {
            let epgList = program.epgList.sort((a, b) => {
                return new Date(a.start) - new Date(b.start);
            });
            let primaryEpg = epgList[0] || null;
            let constructedProgram = {
                ...program,
                thumbnail: `https://image.powernet.tv/${program.imageIds?.[0]}?width=400&height=200`,
                ...(primaryEpg ? primaryEpg : {}),
                programId: program._id
            };
            console.log(constructedProgram);
            return constructedProgram;
        },
        search(searchUrl) {
            axios
                .get(searchUrl)
                .then(({ data }) => {
                    let programs = [...data.programs];
                    programs.forEach((program) => {
                        program.epgList = program.epgList.filter(
                            (listItem) => new Date(listItem.start) < new Date()
                        );
                    });
                    this.searchResults = programs;
                })
                .catch((err) => {
                    console.error(err);
                    message.Error('Kunne ikke hente søge resultater');
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/components/variables.scss';
.streaming {
    padding: 0 50px;
    overflow-y: scroll;
    flex-grow: 1;
    height: 80vh;
    header {
        display: flex;
        justify-content: flex-end;
    }
    .searchResults {
        display: grid;
        grid-auto-rows: max-content;
        gap: 40px 20px;
        max-width: 100%;
        padding: 50px 150px;
        position: relative;
        max-width: max-content;
        margin: 0 auto;
        @include responsive-grid;
    }
    .noResults {
        margin-top: 20px;
        color: rgb(var(--primaryTextColor));
        font-size: 30px;
    }
}
</style>
