<template>
    <div
        :class="{
            player: true,
            hideui: programInfo || programGuide || channelGuide ? true : false,
            archive: playerController.channel ? false : true
        }"
        ref="fullscreenContainer"
    >
        <div class="playerSpace" id="playerSpace">
            <img
                v-if="poster && !playerController.isPlaying"
                :src="poster"
                alt=""
                class="poster"
            />
        </div>
        <PlayerUI
            v-if="playerController.playingEpg && playerController.active"
            :program="playerController.playingEpg"
            :menuOpen="programInfo || programGuide || channelGuide"
            :channel="playingChannel"
        />
        <div
            class="playerMenuOverlay"
            v-if="programInfo || programGuide || channelGuide"
            @click="closeMenu"
        ></div>
        <Transition>
            <ProgramInfo
                v-if="
                    programInfo &&
                    (playerController.playingEpg ||
                        selectedProgram ||
                        setup.defaultProgram)
                "
                :onPlay="
                    (startover) => {
                        onPlayProgram(startover);
                    }
                "
                programContext="epg"
                :playMode="playMode"
                :program="
                    selectedProgram
                        ? selectedProgram
                        : playerController.playingEpg
                        ? playerController.playingEpg
                        : setup.defaultProgram
                        ? setup.defaultProgram
                        : {}
                "
                :exit="closeMenu"
            />
        </Transition>
        <Transition>
            <SimpleGuide
                v-if="
                    programGuide &&
                    playerController.channel &&
                    playerController.playingEpg
                "
                :channel="playerController.channel._id"
                :onSelect="selectProgram"
                :playingProgram="playerController.playingEpg"
                :programList="playerController.channelPrograms"
            />
        </Transition>
        <Transition>
            <ChannelGuide
                v-if="channelGuide"
                :currentChannel="playerController.channel._id"
                :onSelect="switchChannel"
                :channelList="channelList"
            />
        </Transition>
    </div>
</template>

<script>
import axios from 'axios';
import SimpleGuide from './SimpleGuide.vue';
import ChannelGuide from './ChannelGuide.vue';
import ProgramInfo from '@/components/programInfo.vue';
import message from '@/components/statusMessage';
import emitter from 'tiny-emitter/instance';
import PlayerUI from '@/components/PlayerUI.vue';
import playerController, {
    player,
    playFromChannel,
    playFromEpg,
    stopPlayer,
    setPlayingEpg
} from '@/components/PlayerController';
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Player',
    components: {
        SimpleGuide,
        ChannelGuide,
        ProgramInfo,
        PlayerUI
    },
    props: {
        setup: Object,
        onClose: Function
    },
    data() {
        return {
            programInfo: false,
            programGuide: false,
            channelGuide: false,
            selectedProgram: null,
            playingProgram: null,
            statusTimer: null,
            playingChannel: null,
            programCache: [],
            playerUi: false,
            channelList: [],
            playerController
        };
    },
    async mounted() {
        await this.fetchChannelList();
        emitter.on('closePlayer', this.closePlayer);
        emitter.on('openPlayerMenu', this.openPlayerMenu);
        emitter.on('toggleFullscreen', this.toggleFullscreen);
        emitter.on('updatePlayerData', this.updatePlayerData);
    },
    beforeUnmount() {
        if (this.statusTimer) {
            clearInterval(this.statusTimer);
            this.statusTimer = null;
        }
        emitter.off('updatePlayerData', this.updatePlayerData);
        emitter.off('closePlayer', this.closePlayer);
        emitter.off('openPlayerMenu', this.openPlayerMenu);
        emitter.off('toggleFullscreen', this.toggleFullscreen);
    },
    computed: {
        poster() {
            const program = playerController.playingEpg;
            if (!program) return './img/placeholder_img.png';
            return `https://image.powernet.tv/${program.imageIds?.[0]}?width=1280&height=720`;
        }
    },
    methods: {
        toggleFullscreen(state) {
            let fullscreenContainer = this.$refs.fullscreenContainer;
            if (state) {
                if (fullscreenContainer.requestFullscreen) {
                    fullscreenContainer.requestFullscreen();
                } else if (fullscreenContainer.webkitRequestFullscreen) {
                    /* Safari */
                    fullscreenContainer.webkitRequestFullscreen();
                } else if (fullscreenContainer.msRequestFullscreen) {
                    /* IE11 */
                    fullscreenContainer.msRequestFullscreen();
                }
            } else {
                document.exitFullscreen();
            }
        },
        openPlayerMenu(menu) {
            this[menu] = true;
        },
        fetchChannelList() {
            return new Promise((resolve, reject) => {
                let storage = window.localStorage;
                let token = storage.getItem('token');
                let profile = storage.getItem('lastProfile');
                let channelsUrl = this.channelsUrl(token, profile);
                if (!channelsUrl) {
                    reject({
                        message: 'channelsurl could not be constructed'
                    });
                    return;
                }
                axios
                    .get(channelsUrl)
                    .then(({ data }) => {
                        if (!data || data.length < 1) {
                            reject({
                                message: 'no channels'
                            });
                        }

                        this.channelList = data;
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        channelsUrl(token, profile) {
            if (!token || !profile) {
                return '';
            }
            let url = `${this.$store.state.apiUrl}channel?epgMode=NOW&epgFull=true&profile=${profile}&token=${token}`;
            return url;
        },
        updateFavorite(lockState) {
            if (this.selectedProgram) {
                this.selectedProgram.favorite = lockState;
            } else {
                if (this.playingProgram) {
                    this.playingProgram.favorite = lockState;
                }
            }
        },
        pushProgramToCache(_program) {
            let program = { ..._program };
            if (this.programCache.findIndex((o) => o._id == program._id) > -1) {
                return;
            }
            let thumbnail = `https://image.powernet.tv/${program.imageIds?.[0]}?width=300&height=200`;
            program.thumbnail = thumbnail;
            this.$nextTick(() => {
                this.programCache.push(program);
            });
        },
        isProgramLive(program) {
            let start = new Date(program.start);
            let end = new Date(program.end);
            let now = new Date();
            if (start < now && end > now) {
                return true;
            } else {
                return false;
            }
        },
        getFairplayDrm(hlsContentId, data, streamToken) {
            return {
                LA_URL:
                    'https://fps.ezdrm.com/api/licenses/' +
                    hlsContentId +
                    '?streamToken=' +
                    streamToken,
                certificateURL: data.drm.fairplayCertificateUrl,
                prepareContentId: (contentId) => {
                    let uri = contentId;
                    let uriParts = uri.split('://', 1);
                    let protocol = uriParts[0].slice(-3);
                    uriParts = uri.split(';', 2);
                    contentId = uriParts.length > 1 ? uriParts[1] : '';
                    uriParts = contentId.split('?', 2);
                    contentId = uriParts.length > 1 ? uriParts[0] : contentId;
                    return protocol.toLowerCase() == 'skd' ? contentId : '';
                },
                prepareLicenseAsync: (ckc) => {
                    return new Promise((resolve, reject) => {
                        let reader = new FileReader();
                        reader.addEventListener('loadend', () => {
                            resolve(new Uint8Array(reader.result));
                        });
                        reader.addEventListener('error', () => {
                            reject(reader.error);
                        });
                        reader.readAsArrayBuffer(ckc);
                    });
                },
                prepareMessage: (event) => {
                    // eslint-disable-line
                    return new Blob([event.message], {
                        type: 'application/octet-binary'
                    });
                },
                headers: {
                    'content-type': 'application/octet-stream'
                },
                useUint16InitData: true,
                licenseResponseType: 'blob'
            };
        },
        generateStreamUrl(streamId, mode) {
            let localStorage = window.localStorage;
            let apiUrl = this.$store.state.apiUrl;
            let token = localStorage.getItem('token');
            let profile = localStorage.getItem('lastProfile');
            let playMode = mode == 'streaming' ? 'archive' : mode;

            let type = ['safari', 'Safari'].includes(this.detectBrowser())
                ? 'HLS'
                : 'DASH';
            return `${apiUrl}${playMode}/${streamId}/play?token=${token}&profile=${profile}&mode=${type}`;
        },
        handleListProgram(state) {
            if (this.selectedProgram) {
                this.selectedProgram.watchList = state;
                if (
                    this.selectedProgram &&
                    this.playingProgram &&
                    this.selectedProgram.programId ==
                        this.playingProgram.programId
                ) {
                    this.playingProgram.watchList = state;
                }
            } else {
                this.playingProgram.watchList = state;
            }
        },
        getPoster(program) {
            if (!program) return '';
            return `https://image.powernet.tv/${program.imageIds?.[0]}`;
        },
        load(streamUrl, program) {
            let self = this;
            return new Promise((resolve, reject) => {
                let type = ['safari', 'Safari'].includes(self.detectBrowser())
                    ? 'HLS'
                    : 'DASH';
                axios
                    .get(streamUrl)
                    .then((res) => {
                        let data = res.data;
                        self.getContentIdForHLS(res.data.hls).then(
                            (hlsContentId) => {
                                const streamToken =
                                    type == 'HLS'
                                        ? res.data.hls
                                              .split('token=')[1]
                                              .split('&')[0]
                                        : res.data.dash
                                              .split('token=')[1]
                                              .split('&')[0];
                                var source = {
                                    poster: `https://image.powernet.tv/${program.imageIds?.[0]}`,
                                    title: program.title,
                                    description: program.description,
                                    metadata: {
                                        title: program.title,
                                        subtitle: program.subtitle,
                                        start: new Date(
                                            program.start
                                        ).getTime(),
                                        duration: program.duration * 60,
                                        streamType:
                                            self.programMode == 'channel'
                                                ? 'LIVE'
                                                : 'BUFFERED',
                                        token: window.localStorage.getItem(
                                            'token'
                                        ),
                                        mode:
                                            self.programMode == 'channel'
                                                ? 'LIVE'
                                                : 'archive',
                                        channelName: self.playingChannel?.name,
                                        channelLogo: self.playingChannel?.logo,
                                        images: [
                                            {
                                                url: `https://image.powernet.tv/${program.imageIds?.[0]}?width=1777&height=1000`,
                                                width: 1777,
                                                height: 1000
                                            }
                                        ]
                                    },
                                    drm: {
                                        widevine: {
                                            LA_URL: data.drm.widevineLicenseUrl.replace(
                                                'TOKEN',
                                                streamToken
                                            )
                                        },
                                        playready: {
                                            LA_URL: data.drm.playreadyLicenseUrl.replace(
                                                'TOKEN',
                                                streamToken
                                            )
                                        },
                                        fairplay: self.getFairplayDrm(
                                            hlsContentId,
                                            data,
                                            streamToken
                                        ),
                                        preferredKeySystems: ['playready'],
                                        mediaKeySystemConfig: {
                                            persistentState: 'required',
                                            sessionTypes: ['persistent-license']
                                        }
                                    }
                                };
                                if (type == 'DASH') {
                                    // let start = new Date(program.start);
                                    if (this.playMode == 'channel') {
                                        // source.dash = res.data.dash.replace(
                                        //     'index.mpd',
                                        //     `index-${
                                        //         parseInt(start.getTime()) / 1000
                                        //     }-now.mpd`
                                        // );
                                    } else {
                                        source.dash = res.data.dash;
                                    }
                                    source.dash += '&period=mono';
                                }
                                if (type == 'HLS') {
                                    let start = new Date(program.start);
                                    if (this.playMode == 'channel') {
                                        source.hls =
                                            res.data.hls.replace(
                                                'index.m3u8',
                                                `index-${
                                                    parseInt(start.getTime()) /
                                                    1000
                                                }-now.m3u8`
                                            ) + '&period=mono';
                                    } else {
                                        source.hls = res.data.hls;
                                    }
                                    source.hls += '&period=mono';
                                }
                                // eslint-disable-next-line no-undef
                                player.load(source).then(() => {
                                    // eslint-disable-next-line
                                    resolve(source);
                                });
                            }
                        );
                    })
                    .catch((error) => {
                        let responseCode = error.response?.data?.error?.code;
                        if (responseCode) {
                            switch (responseCode) {
                                case 'no_streams':
                                    message.Error(
                                        'Det maksimale antal samtidige afspilninger for husstanden er nået'
                                    );
                                    self.closePlayer();
                                    break;
                                case 'program':
                                    message.Error(
                                        'Programmet kunne ikke afspilles'
                                    );
                                    break;
                                case 'geoblock':
                                    message.Error(
                                        'Afspilning ikke muligt da du er uden den tilladte geografiske zone'
                                    );
                                    break;
                                case 'access_denied':
                                    message.Error(
                                        'Du har ikke adgang til indholdet, prøv igen senere eller kontakt support'
                                    );
                                    break;
                                case 'internal_error':
                                    message.Error(
                                        'Der er opstået en uventet fejl, kontakt support hvis fejlen opstår igen'
                                    );
                                    break;
                            }
                        }
                        console.error(error);
                        self.$store.dispatch('validateLogin');
                        reject(error);
                    });
            });
        },
        stopPlaying(mode, id, callback) {
            emitter.emit('stopArchiveTimer');
            let profile = window.localStorage.getItem('lastProfile');
            let token = window.localStorage.getItem('token');
            let position = 0;
            if (mode != 'channel') {
                // eslint-disable-next-line
                position = Math.floor(player.getCurrentTime());
            }
            axios
                .get(
                    this.$store.state.apiUrl +
                        `${
                            mode == 'streaming' ? 'archive' : mode
                        }/${id}/stop?token=${token}${
                            profile ? `&profile=${profile}` : ''
                        }${mode != 'channel' ? `&position=${position}` : ''}`
                )
                .then(() => {
                    callback(true);
                })
                .catch((err) => {
                    console.error(err);
                    callback(false);
                });
        },
        closePlayer() {
            this.playerUi = false;
            stopPlayer(true)
                .then(() => {
                    this.onClose();
                })
                .catch(() => {
                    this.onClose();
                });
        },
        fetchProgram(programId) {
            if (!programId) return;
            let token = window.localStorage.getItem('token');
            let profile = this.$store.state.user.profile._id;
            let dataUrl = `${
                this.$store.state.apiUrl
            }epg/${programId}?token=${token}${
                profile ? `&profile=${profile}` : ''
            }`;
            return axios
                .get(dataUrl)
                .then((res) => {
                    let program = res.data;
                    if (program) {
                        delete program.epgList;
                        return program;
                    } else {
                        return null;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    return null;
                });
        },
        async onPlayProgram(startover) {
            const program = this.selectedProgram
                ? this.selectedProgram
                : playerController.playingEpg;

            await stopPlayer();
            playFromEpg(program._id, {
                playMode: 'archive',
                startOver: startover,
                position: startover ? 0 : program.position
            }).then(() => {
                this.programInfo = false;
            });
        },
        closeMenu() {
            this.resetMenuButtons();
            this.programInfo = false;
            this.programGuide = false;
            this.channelGuide = false;
            this.selectedProgram = null;
            if (player) {
                let source = player.getSource();
                if (!source) {
                    this.closePlayer();
                }
            } else {
                this.closePlayer();
            }
        },
        detectBrowser() {
            if (
                (navigator.userAgent.indexOf('Opera') ||
                    navigator.userAgent.indexOf('OPR')) != -1
            ) {
                return 'Opera';
            } else if (navigator.userAgent.indexOf('Chrome') != -1) {
                return 'Chrome';
            } else if (navigator.userAgent.indexOf('Safari') != -1) {
                return 'Safari';
            } else if (navigator.userAgent.indexOf('Firefox') != -1) {
                return 'Firefox';
            } else if (
                navigator.userAgent.indexOf('MSIE') != -1 ||
                !!document.documentMode == true
            ) {
                return 'IE'; //crap
            } else {
                return 'Unknown';
            }
        },
        getContentIdForHLS(url) {
            let self = this;
            if (
                !['safari', 'ios'].includes(self.detectBrowser().toLowerCase())
            ) {
                return Promise.resolve('');
            }
            return axios
                .get(url)
                .then((res) => {
                    const manifest = res.data;
                    if (manifest.includes('#EXT-X-KEY:')) {
                        return res;
                    } else {
                        if (manifest.includes('tracks-')) {
                            const url2 = manifest
                                .split('\n')
                                .filter((o) => o.startsWith('tracks-'))[0];
                            if (!url2) {
                                throw new Error();
                            }
                            const newUrl =
                                url.substr(0, url.lastIndexOf('/')) +
                                '/' +
                                url2;
                            return axios.get(newUrl);
                        } else {
                            throw new Error();
                        }
                    }
                })
                .then((res) => {
                    const contentId = res.data
                        .split('\n')
                        .filter((o) => o.startsWith('#EXT-X-KEY:'))[0]
                        .split('URI=')[1]
                        .split('"')[1]
                        .split(';')[1];
                    return contentId;
                })
                .catch(() => {
                    return '';
                });
        },
        resetMenuButtons() {
            let activeButtons = document.querySelectorAll(
                'button.menuBtn.active'
            );
            for (let i = 0; i < activeButtons.length; i++) {
                const button = activeButtons[i];
                button.classList.remove('active');
            }
        },
        async switchChannel(channelId) {
            if (channelId == playerController.streamId) return;

            await stopPlayer();
            await playFromChannel(channelId).then(() => {});
            this.programCache = [];
            this.fetchProgramList(channelId).then((list) => {
                this.playingChannelPrograms = list;
            });
            this.channelGuide = false;
        },
        setPlayerLangs(player) {
            let self = this;
            let audioLangs = player.getAvailableAudio();
            let selectedLang = self.$store.state.user.profile.audioLanguage
                ? self.$store.state.user.profile.audioLanguage
                : 'dan';
            let selectedAudioLang = audioLangs.find((o) => {
                return o.lang == selectedLang;
            });
            if (selectedAudioLang) {
                player.setAudio(selectedAudioLang.id);
            } else {
                if (
                    audioLangs &&
                    audioLangs.findIndex((o) => o.lang == 'dan')
                ) {
                    let language = audioLangs.find((o) => {
                        return o.lang == 'dan';
                    });
                    if (language) {
                        player.setAudio(language._id);
                    }
                }
            }
        },
        startOver(program) {
            if (
                // eslint-disable-next-line no-undef
                player &&
                // eslint-disable-next-line no-undef
                typeof player.timeShift == 'function' &&
                // eslint-disable-next-line no-undef
                player.isPlaying()
            ) {
                setTimeout(() => {
                    // eslint-disable-next-line no-undef
                    player.timeShift(
                        // eslint-disable-next-line no-undef
                        player.getMaxTimeShift()
                    );
                }, 10);
                // eslint-disable-next-line no-undef
            } else if (player) {
                let streamUrl = this.generateStreamUrl(
                    this.currentStream,
                    'channel'
                );
                this.playingProgram = program;
                this.play(streamUrl, true);
            }
        },
        correctClockNum(number) {
            return number < 10 ? '0' + number : number;
        },
        selectProgram(program) {
            this.selectedProgram = program;
            this.context = 'epg';
            this.programGuide = false;
            this.channelGuide = false;
            this.programInfo = true;
        },
        updateThumbnail(url) {
            let thumbnailElem = document.getElementById('playerThumbnail');
            if (thumbnailElem) {
                thumbnailElem.src = url;
            }
        },
        updatePlayerDataFromCache(timestamp) {
            let date = new Date(timestamp);
            const program = this.programCache.find((o) => {
                return new Date(o.start) < date && new Date(o.end) > date;
            });
            if (!program) {
                return;
            }
            if (
                playerController.playingEpg &&
                program._id == playerController.playingEpg._id
            ) {
                return;
            }

            setPlayingEpg(program);
            this.updateThumbnail(program.thumbnail);
        },
        updatePlayerData() {
            const token = localStorage.getItem('token');
            const profile = localStorage.getItem('lastProfile');
            const baseChannel = playerController.channel
                ? playerController.channel.baseChannel
                : null;
            if (!profile || !token || !baseChannel) {
                return;
            }
            let apiUrl = this.$store.state.apiUrl;
            let dataUrl = `${apiUrl}epgnow/${baseChannel}?token=${token}&profile=${profile}`;
            axios
                .get(dataUrl)
                .then((res) => {
                    let data = res.data;
                    if (!data) {
                        console.error('Der er ikke noget data fra epgnow');
                        return;
                    }
                    this.pushProgramToCache(res.data);
                    this.$nextTick(() => {
                        this.updatePlayerDataFromCache(
                            player.getCurrentTime() * 1000
                        );
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import './variables.scss';
.player {
    position: fixed;
    height: 100vh;
    width: 100vw;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgb(var(--primaryBackground));
    overflow: hidden;
    text-align: left;
    display: flex;
    transition: 0.3s cubic-bezier(0, 0, 0, 1.1);
    &.miniature {
        bottom: 30px;
        right: 30px;
        height: 300px;
        width: 400px;
        border-radius: 20px;
    }
    .exitMiniature {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(var(--primaryBackground));
        cursor: pointer;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 9999;
        svg {
            transform: scale(-1, 1);
            height: 20px;
            width: 20px;
            path {
                fill: rgb(var(--themeColor));
            }
        }
    }
    .playerMenuOverlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }
    .playerSpace {
        height: 100%;
        width: 100%;
        flex-shrink: 0;
        background-color: black;
        position: relative;
        .poster {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
    .playerMenu {
        position: absolute;
        z-index: 950;
        .inner {
            width: max-content;
            z-index: 99;
            padding: 20px;
            height: 100%;
            background-color: rgba(var(--primaryBackground), 0.2);
        }
        &.v-enter-from {
            transform: translateX(200px);
        }
        &.v-leave-to {
            transform: translateX(200px);
        }
        &.channelGuide {
            left: 0;
            right: unset;
            .inner {
                overflow: hidden;
            }
            .closeMenu {
                left: 100%;
                right: unset;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            &.v-enter-from {
                transform: translateX(-200px);
            }
            &.v-leave-to {
                transform: translateX(-200px);
            }
        }

        .closeMenu {
            display: none;
            position: absolute;
            right: 100%;
            top: 0;
            height: 100%;
            width: 60px;
            background-color: rgba(var(--secondaryBackground), 0.8);
            z-index: 0;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            cursor: pointer;
            transition: width 0.1s linear;
            svg {
                transition: width 0.1s linear, height 0.1s linear;
                height: 40px;
                width: 40px;
                path {
                    transition: fill 0.1s linear;
                    fill: rgb(var(--secondaryTextColor));
                }
            }
            &:hover {
                width: 80px;
                svg {
                    height: 60px;
                    width: 60px;
                    path {
                        fill: rgb(var(--primaryTextColor));
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
@import './variables.scss';
.player {
    &.archive {
        .programGuide {
            display: none;
        }
    }
    .live {
        .bmpui-ui-seekbar {
            visibility: hidden;
        }
    }
    video {
        transition: filter;
        transition-duration: 0.3s;
        transition-timing-function: ease;
    }
    .playerSpace[data-fullscreen='true'] button.menuBtn {
        visibility: hidden;
    }
    .bmpui-ui {
        color: rgb(var(--themeColor));
        &-watermark {
            display: none;
        }
    }
}
</style>
