<template>
    <div :class="`service ${disabled ? 'disabled' : ''}`">
        <img :src="info.logo" alt="" class="serviceLogo" />
        <div class="serviceContent">
            <h3 class="serviceName">{{ info.name }}</h3>
            <p v-if="startTime" class="programStart">{{ startTime }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EpgListItem',
    props: {
        info: Object
    },
    computed: {
        startTime() {
            let time = this.info.start;
            if (!time) {
                return '';
            }
            let date = new Date(time);

            return `${this.twoDigit(date.getDate())}. ${this.twoDigit(
                new Date(date).toLocaleString(window.navigator.language, {
                    month: 'short'
                })
            )}. ${date.getFullYear()} ${this.twoDigit(
                date.getHours()
            )}.${this.twoDigit(date.getMinutes())}`;
        },
        disabled() {
            let start = this.info.start;
            if (!start) {
                return false;
            }
            let date = new Date(start);
            if (new Date() < date) {
                return true;
            }
            return false;
        }
    },
    methods: {
        twoDigit(num) {
            return num < 10 ? '0' + num : num;
        }
    }
};
</script>

<style lang="scss" scoped>
.service {
    display: flex;
    background-color: rgb(var(--secondaryBackground));
    border-radius: 10px;
    overflow: hidden;
    height: 70px;
    width: 100%;
    transition: background-color;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    cursor: pointer;
    .serviceContent {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
        text-align: start;

        .serviceName {
            font-size: 22px;
            transition: color;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }
        .programStart {
            font-size: 15px;
            transition: color;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }
    }
    img.serviceLogo {
        height: 100%;
        width: auto;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
    }
    &:not(.disabled):hover {
        background-color: rgb(var(--primaryTextColor));
        .serviceContent {
            .serviceName {
                color: rgb(var(--primaryBackground));
            }
            .programStart {
                color: rgb(var(--secondaryBackground));
            }
        }
    }
    &.disabled {
        filter: grayscale(1);
    }
}
</style>
