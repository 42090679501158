<template>
    <div class="profileSettings">
        <h1 class="settingsTitle" v-if="profileData.name">
            Indstillinger for {{ profileData.name }}
        </h1>
        <div class="profileSettingsMain">
            <div class="general">
                <SettingCategory heading="Navn">
                    <div class="settingItem">
                        <input
                            type="text"
                            class="settingTitle"
                            v-model="profileName"
                            @input="
                                (e) => {
                                    updateProfileName(e.target.value);
                                }
                            "
                        />
                    </div>
                </SettingCategory>

                <SettingCategory heading="Indstillinger">
                    <div class="settingItem radio">
                        <input
                            type="checkbox"
                            name="subtitleCheck"
                            id="subtitleCheck"
                        />
                        <label for="subtitleCheck" class="settingTitle"
                            >Undertekster
                            <div class="accordionLabel">
                                <span class="accordionLabelTitle">{{
                                    subtitleLanguage
                                }}</span>
                                <svg
                                    id="glyphicons-basic"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        id="chevron-down"
                                        d="M26.91425,13.12115,16.70709,23.32849a.99988.99988,0,0,1-1.41418,0L5.08575,13.12115a.50007.50007,0,0,1,0-.70715l2.8285-2.82806a.5.5,0,0,1,.70709,0L16,16.96436l7.37866-7.37842a.5.5,0,0,1,.70709,0l2.8285,2.82806A.50007.50007,0,0,1,26.91425,13.12115Z"
                                    />
                                </svg>
                            </div>
                        </label>

                        <div class="content">
                            <div
                                class="radioInput"
                                v-for="item in languages"
                                :key="item.short"
                            >
                                <input
                                    type="radio"
                                    :value="item.short"
                                    :id="`${item.short}_sub`"
                                    @click="
                                        () => {
                                            handleChange(
                                                'subtitleLanguage',
                                                item.short
                                            );
                                        }
                                    "
                                />
                                <svg
                                    v-if="subtitleLanguage == item.short"
                                    id="glyphicons-basic"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        id="check"
                                        d="M27.37592,9.70459l-14.151,15.97693a.99985.99985,0,0,1-1.47558.02356L4.59711,18.1322a.99992.99992,0,0,1-.05384-1.31128L5.495,15.63123a.99994.99994,0,0,1,1.22808-.26966L12,18,24.79724,7.09863a.99991.99991,0,0,1,1.35553.0542l1.1817,1.18164A1,1,0,0,1,27.37592,9.70459Z"
                                    />
                                </svg>
                                <label :for="`${item.short}_sub`">{{
                                    item.long
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="settingItem radio">
                        <input
                            type="checkbox"
                            name="languageCheck"
                            id="languageCheck"
                        />
                        <label for="languageCheck" class="settingTitle"
                            >Lyd
                            <div class="accordionLabel">
                                <span>{{ audioLanguage }}</span>
                                <svg
                                    id="glyphicons-basic"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        id="chevron-down"
                                        d="M26.91425,13.12115,16.70709,23.32849a.99988.99988,0,0,1-1.41418,0L5.08575,13.12115a.50007.50007,0,0,1,0-.70715l2.8285-2.82806a.5.5,0,0,1,.70709,0L16,16.96436l7.37866-7.37842a.5.5,0,0,1,.70709,0l2.8285,2.82806A.50007.50007,0,0,1,26.91425,13.12115Z"
                                    />
                                </svg>
                            </div>
                        </label>

                        <div class="content">
                            <div
                                class="radioInput"
                                v-for="item in languages"
                                :key="item.short"
                            >
                                <input
                                    type="radio"
                                    :value="item.short"
                                    :id="`${item.short}_lang`"
                                    @click="
                                        () => {
                                            handleChange(
                                                'audioLanguage',
                                                item.short
                                            );
                                        }
                                    "
                                />
                                <svg
                                    v-if="audioLanguage == item.short"
                                    id="glyphicons-basic"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        id="check"
                                        d="M27.37592,9.70459l-14.151,15.97693a.99985.99985,0,0,1-1.47558.02356L4.59711,18.1322a.99992.99992,0,0,1-.05384-1.31128L5.495,15.63123a.99994.99994,0,0,1,1.22808-.26966L12,18,24.79724,7.09863a.99991.99991,0,0,1,1.35553.0542l1.1817,1.18164A1,1,0,0,1,27.37592,9.70459Z"
                                    />
                                </svg>
                                <label :for="`${item.short}_lang`">{{
                                    item.long
                                }}</label>
                            </div>
                        </div>
                    </div>
                </SettingCategory>
                <SettingCategory heading="Tilgængelighed">
                    <div class="settingItem">
                        <span class="settingTitle"
                            >Undertekster for hørehæmmede</span
                        >
                        <div class="settingAction">
                            <Toggle
                                :onSet="setUsability"
                                :activeState="sdhState"
                            />
                        </div>
                    </div>
                </SettingCategory>
                <SettingCategory heading="Indhold">
                    <router-link to="channels" class="settingItem">
                        <h3 class="settingTitle">Kanalopsætning</h3>
                        <svg
                            id="glyphicons-basic"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                        >
                            <path
                                id="chevron-right"
                                d="M23.32849,16.70709,13.12115,26.91425a.50007.50007,0,0,1-.70715,0l-2.82806-2.8285a.5.5,0,0,1,0-.70709L16.96436,16,9.58594,8.62134a.5.5,0,0,1,0-.70709L12.414,5.08575a.50007.50007,0,0,1,.70715,0L23.32849,15.29291A.99988.99988,0,0,1,23.32849,16.70709Z"
                            />
                        </svg>
                    </router-link>
                    <router-link to="streaming" class="settingItem">
                        <h3 class="settingTitle">Streamingtjenester</h3>
                        <svg
                            id="glyphicons-basic"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                        >
                            <path
                                id="chevron-right"
                                d="M23.32849,16.70709,13.12115,26.91425a.50007.50007,0,0,1-.70715,0l-2.82806-2.8285a.5.5,0,0,1,0-.70709L16.96436,16,9.58594,8.62134a.5.5,0,0,1,0-.70709L12.414,5.08575a.50007.50007,0,0,1,.70715,0L23.32849,15.29291A.99988.99988,0,0,1,23.32849,16.70709Z"
                            />
                        </svg>
                    </router-link>
                </SettingCategory>
                <SettingCategory
                    heading="Optagelser"
                    :description="`Sletter alle optagelser for profilen: ${profileData.name}`"
                >
                    <button
                        class="deleteRecordings deleteBtn profileActionButton"
                        @click="deleteProfileRecordings"
                    >
                        Slet profilens optagelser
                    </button>
                </SettingCategory>
                <button
                    class="deleteProfile deleteBtn profileActionButton"
                    @click="deleteProfile"
                >
                    Slet denne profil
                </button>
            </div>
            <router-view v-slot="{ Component, route }">
                <transition :name="route.meta.transition">
                    <component :is="Component" />
                </transition>
            </router-view>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import SettingCategory from '../components/SettingCategory.vue';
import emitter from 'tiny-emitter/instance';
import message from '@/components/statusMessage';
import Toggle from '@/components/Toggle.vue';
export default {
    name: 'ProfileSettings',
    data() {
        return {
            profileData: {},
            channels: [],
            profileName: '',
            initial: true,
            subtitleLanguage: '',
            audioLanguage: '',
            sdhState: false,
            languages: [
                {
                    short: 'dan',
                    long: 'Dansk'
                },
                {
                    short: 'eng',
                    long: 'Engelsk'
                },
                {
                    short: 'swe',
                    long: 'Svensk'
                },
                {
                    short: 'nor',
                    long: 'Norsk'
                },
                {
                    short: 'fin',
                    long: 'Finsk'
                }
            ]
        };
    },
    components: {
        SettingCategory,
        Toggle
    },
    mounted() {
        this.fetchProfile(this.$route.params.id);
    },
    methods: {
        setUsability(state, save) {
            this.sdhState = state;
            if (save) {
                this.saveProfile({
                    useSdhSubtitles: state
                });
            }
        },
        handleChange(category, value) {
            let body = {};
            body[category] = value;
            this.saveProfile(body);
            this[category] = value;
        },
        handleAddProfile(e) {
            e.preventDefault();
            let self = this;
            let token = window.localStorage.getItem('token');
            let form = e.target;
            let dataUrl = `${this.$store.state.apiUrl}drmprofile?token=${token}`;
            axios
                .post(
                    dataUrl,
                    {
                        name: form.profileName.value
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                .then((res) => {
                    self.$router.push(`/settings/${res.data._id}`);
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        fetchProfile(profileId) {
            let self = this;
            let token = window.localStorage.getItem('token');
            let dataUrl = `${this.$store.state.apiUrl}drmprofile/${profileId}?token=${token}`;
            axios
                .get(dataUrl)
                .then((res) => {
                    this.initial = true;
                    self.profileName = res.data.name;
                    self.profileData = res.data;
                    self.subtitleLanguage = res.data.subtitleLanguage;
                    self.audioLanguage = res.data.audioLanguage;
                    self.setUsability(res.data.useSdhSubtitles, false);
                })
                .catch((err) => {
                    console.error(err);
                    self.$store.dispatch('validateLogin');
                });
        },
        toggleChannel(state, channel) {
            this.channels.find((o) => {
                if (o._id == channel) {
                    o.enabled = state;
                }
            });
        },
        updateProfileName(profileName) {
            let self = this;
            if (this.initial) {
                this.initial = false;
            }
            if (this.nameLimiter) {
                clearTimeout(this.nameLimiter);
            }
            this.nameLimiter = setTimeout(function () {
                if (!profileName) return;
                self.saveProfile({
                    name: profileName
                });
            }, 600);
        },
        deleteProfile() {
            let profileId = this.$route.params.id;
            let token = window.localStorage.getItem('token');
            let dataUrl = `${this.$store.state.apiUrl}drmprofile/${profileId}?token=${token}`;
            let self = this;
            axios
                .delete(dataUrl)
                .then((res) => {
                    if (res.data.success == true) {
                        self.$router.push({ path: '/settings' });
                        emitter.emit('profileChange');
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        saveProfile(body) {
            let self = this;
            let profileId = this.$route.params.id;
            let token = window.localStorage.getItem('token');
            let dataUrl = `${this.$store.state.apiUrl}drmprofile/${profileId}?token=${token}`;
            axios
                .post(dataUrl, body, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(({ data }) => {
                    self.$store.state.user.profile = data;
                    emitter.emit('profileChange');
                    message.Success('Profilen er opdateret');
                })
                .catch((err) => {
                    console.error(err);
                    message.Error('Profilen kunne ikke opdateres');
                });
        },
        deleteProfileRecordings() {
            let profile = this.$route.params.id;
            if (!profile) return;
            let self = this;
            let localStorage = window.localStorage;
            let dataUrl = `${
                this.$store.state.apiUrl
            }recording/ALL/delete?token=${localStorage.getItem(
                'token'
            )}&profile=${profile}`;
            axios
                .get(dataUrl)
                .then((res) => {
                    if (res.data.success == true) {
                        message.Success(
                            `Slettede optagelser for: ${self.profileData.name}`
                        );
                    }
                })
                .catch((err) => {
                    console.error(err);
                    message.Error(
                        `Kunne ikke slette optagelser for: ${self.profileData.name}`
                    );
                });
        }
    },
    watch: {
        $route(route) {
            if (route.params.id) {
                this.fetchProfile(route.params.id);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../components/variables.scss';
.profileSettings {
    grid-area: settings;
    display: flex;
    flex-direction: column;
    input {
        background-color: transparent;
        &:focus {
            outline: 0;
        }
    }
    .profileActionButton {
        cursor: pointer;
    }
    .profileSettingsMain {
        flex-grow: 1;
        display: flex;
        position: relative;
        overflow: hidden;
        height: 1px;
        .settingItem.radio {
            flex-wrap: wrap;
            padding: 0;
            & > label {
                cursor: pointer;
                padding: 10px 20px;
            }
            input[type='radio'] {
                -webkit-appearance: none;
            }
            input[type='checkbox'] {
                display: none;
                &:checked {
                    & ~ .content {
                        max-height: 200px;
                    }
                    & ~ .settingTitle {
                        color: rgb(var(--primaryTextColor));
                        svg {
                            transform: rotateZ(180deg);
                        }
                    }
                }
            }
            .accordionLabel {
                display: flex;
                align-items: center;
                :first-child {
                    margin-right: 10px;
                }
            }

            .settingTitle {
                width: 100%;
                display: flex;
                justify-content: space-between;
                svg {
                    transition: transform 0.15s linear;
                }
            }
            .content {
                width: 100%;
                overflow: hidden;
                max-height: 0;
                transition: max-height 0.15s linear;
                position: relative;
                padding: 0 10px;
                background-color: rgb(var(--secondaryBackground));
                filter: brightness(0.9);
                .radioInput {
                    display: grid;
                    grid-template-columns: 30px 1fr;
                    grid-template-areas: 'check label';
                    cursor: pointer;
                    &:hover {
                        color: rgb(var(--primaryTextColor));
                    }
                    input {
                        display: none;
                    }
                    & > * {
                        padding: 5px 0;
                    }
                    label {
                        grid-area: label;
                        cursor: pointer;
                        color: rgb(var(--secondaryTextColor));
                        &:hover {
                            color: rgb(var(--primaryTextColor));
                        }
                    }
                    svg {
                        grid-area: check;
                        height: 100%;
                    }
                }
            }
        }
        .deleteBtn {
            width: 100%;
            text-align: center;
            padding: 15px 0;
            font-size: 17px;
            background-color: rgb(var(--themeColor));
            border-radius: 10px;
            &.deleteProfile {
                background-color: red;
                margin-top: 20px;
            }
        }
        .general {
            flex-grow: 1;
            overflow: scroll;
        }
    }
}
</style>
