<template>
    <LoadingIcon :size="30" v-if="loading" />
    <TransitionGroup name="channels" tag="div" class="tvMain" v-if="!noResults">
        <ProgramCard
            v-for="channel in channels"
            @click="
                () => {
                    openPlayer(channel);
                }
            "
            :key="channel._id"
            mode="tv"
            :program="{
                ...channel?.epg?.[0],
                thumbnail: channel?.epg?.[0]?.imageIds?.[0]
                    ? `https://image.powernet.tv/${channel?.epg?.[0]?.imageIds?.[0]}?width=400&height=200`
                    : null,
                nextProgram: channel.epg?.[1],
                live: true,
                channelLogo: channel.logo,
                position: undefined
            }"
        />
    </TransitionGroup>
    <h1 class="errorMessage" v-if="noResults">Der er ingen kanaler at vise</h1>
</template>

<script>
// @ is an alias to /src
import ProgramCard from '../components/programCard.vue';
import axios from 'axios';
import emitter from 'tiny-emitter/instance';
import LoadingIcon from '@/components/LoadingIcon.vue';
import { playFromChannel } from '@/components/PlayerController';
export default {
    data() {
        return {
            player: false,
            playerSetup: {},
            channels: {},
            displayError: false,
            errorMessage: '',
            loading: false,
            epgTimer: null,
            noResults: false
        };
    },
    name: 'TvMain',
    components: {
        ProgramCard,
        LoadingIcon
    },
    methods: {
        openPlayer(channel) {
            const channelId = channel._id;
            playFromChannel(channelId);
        },
        closePlayer() {
            this.player = false;
            this.playerSetup = {};
        },
        correctClockNum(number) {
            return number < 10 ? '0' + number : number;
        },
        getImage(imageArray, type) {
            return imageArray.find((o) => o.type == type) || null;
        },
        addToList(program) {
            console.log(program);
        },
        fetchSingleChannel(channelId) {
            let self = this;
            let token = window.localStorage.getItem('token');
            return axios
                .get(
                    self.$store.state.apiUrl +
                        'channel?token=' +
                        token +
                        '&epgFull=true&epgMode=NOW' +
                        '&channel=' +
                        channelId
                )
                .then((res) => res)
                .catch((err) => {
                    console.error(err);
                    self.$store.dispatch('validateLogin');
                });
        },
        checkEpg() {
            let self = this;
            for (const channel in self.channels) {
                let epg = self.channels[channel].epg;
                if (epg && epg[0]) {
                    if (new Date(epg[0].end) < new Date()) {
                        self.channels[channel].epg.shift();

                        self.fetchSingleChannel(channel).then((res) => {
                            if (res.data[0]) {
                                self.channels[channel] = res.data[0];
                            }
                        });
                    }
                }
            }
        },
        fetchChannels() {
            let store = this.$store;
            let localStorage = window.localStorage;
            let token = localStorage.getItem('token');
            let profile = localStorage.getItem('lastProfile');
            if (!profile) return;
            let self = this;
            this.loading = true;
            axios
                .get(
                    store.state.apiUrl +
                        'channel?' +
                        'token=' +
                        token +
                        '&epgMode=NOW&epgFull=true' +
                        `&profile=${profile}`
                )
                .then((res) => {
                    let data = res.data;
                    let channelObj = {};
                    for (let i = 0; i < data.length; i++) {
                        const channel = data[i];
                        channelObj[channel._id] = channel;
                    }
                    self.channels = channelObj;
                    self.epgTimer = setInterval(self.checkEpg, 120 * 1000);
                    self.loading = false;
                    if (data.length < 1) {
                        self.noResults = true;
                    } else {
                        self.noResults = false;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    self.loading = false;
                    self.$store.dispatch('validateLogin');
                });
        }
    },
    mounted() {
        let self = this;
        this.fetchChannels();
        emitter.on('profileChange', function () {
            self.fetchChannels();
        });
    },
    beforeUnmount() {
        if (this.epgTimer) {
            clearInterval(this.epgTimer);
            this.epgTimer = null;
        }
    }
};
</script>

<style lang="scss">
@import '../components/variables.scss';
.tvMain {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: 40px 20px;
    max-width: 100%;
    padding: 50px 150px;
    position: relative;
    margin: 0 auto;
    @include responsive-grid;
    .loadingIcon {
        height: 100px;
        width: 100px;
        flex-grow: 0;
        position: absolute;
        top: 50px;
        left: calc(50% - 50px);
        flex-shrink: 0;
        animation: spinner linear 1s infinite;
        display: flex;
        justify-content: center;

        &Inner {
            height: 30px;
            width: 30px;
            background-color: rgb(var(--primaryTextColor));
            border-radius: 50%;
        }
    }
}

.errorMessage {
    margin-top: 50px;
    color: rgb(var(--secondaryTextColor));
}

@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
