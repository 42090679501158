<template>
    <div
        :class="{
            channelCard: true,
            selected: currentChannel == channel._id ? true : false
        }"
        @click="onSelect(channel._id)"
    >
        <img class="channelLogo" :src="channel.logo" alt="" />
        <div class="content">
            <p class="playingNow">
                <span class="duration"
                    >{{ programStart }} - {{ programEnd }}</span
                >
                {{ currentProgram.title }}
            </p>
            <ProgressBar
                class="progressbar"
                :customWidth="`${programProgress}`"
            />
            <p class="nextProgram">{{ nextStart }} {{ nextProgram?.title }}</p>
        </div>
    </div>
</template>

<script>
import ProgressBar from './ProgressBar.vue';
import emitter from 'tiny-emitter/instance';
export default {
    name: 'ChannelCard',
    components: {
        ProgressBar
    },
    data() {
        return {
            currentProgram: {},
            nextProgram: {},
            programStart: '',
            programEnd: '',
            nextStart: ''
        };
    },
    props: {
        currentChannel: String,
        channel: Object,
        onSelect: Function
    },
    computed: {
        programProgress() {
            let start = new Date(this.currentProgram.start);
            let now = new Date();
            let progress = now - start;
            let procent =
                (progress / 1000 / (this.currentProgram.duration * 60)) * 100;
            return procent;
        }
    },
    beforeMount() {
        this.setCurrentProgram();
    },
    mounted() {
        this.programStart = this.constructTime(this.currentProgram.start);
        if (this.currentProgram.end) {
            this.programEnd = this.constructTime(this.currentProgram.end);
        }
        if (this.nextProgram) {
            this.nextStart = this.constructTime(this.nextProgram.start);
        }
    },
    methods: {
        constructTime(time) {
            let t = new Date(time);
            let h = t.getHours() < 10 ? '0' + t.getHours() : t.getHours();
            let m = t.getMinutes() < 10 ? '0' + t.getMinutes() : t.getMinutes();
            return `${h}:${m}`;
        },
        setCurrentProgram() {
            let epg = this.channel.epg;
            if (!epg) return;
            let currentEpg = epg.findIndex((o) => {
                let start = new Date(o.start);
                let end = new Date(o.end);
                let now = new Date();
                if (start < now && end > now) {
                    return o;
                }
            });
            if (currentEpg > -1) {
                this.currentProgram = epg[currentEpg];
                if (epg[currentEpg + 1]) {
                    this.nextProgram = epg[currentEpg + 1];
                }
            } else {
                emitter.emit('refreshEpg');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/components/variables.scss';
.channelCard {
    display: flex;
    height: 100px;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 15px;
    background-color: rgb(var(--secondaryBackground));
    transition: box-shadow 0.1s linear, transform 0.1s linear;
    margin: 0 20px;
    cursor: pointer;
    &.selected {
        border: 3px solid rgb(var(--themeColor));
    }
    &:first-of-type {
        margin-top: 10px;
    }
    &:last-of-type {
        margin-bottom: 10px;
    }
    &:hover {
        transform: scale(1.05);
    }
    img.channelLogo {
        height: 100%;
        width: 8rem;
        flex-grow: 0;
        object-fit: contain;
        background-color: white;
    }
    .content {
        margin: 10px 5px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .playingNow {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
            color: rgb(var(--primaryTextColor));
            font-weight: bold;
            font-size: 23px;
            line-height: 26px;
            span.duration {
                margin-right: 5px;
                font-size: 15px;
                color: rgb(var(--secondaryTextColor));
            }
        }
        .progressbar {
            width: 70%;
        }
        .nextProgram {
            margin-top: auto;
        }
    }
}
</style>
